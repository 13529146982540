import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { formatISO, startOfMonth } from 'date-fns';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { showToaster } from 'mixin/general';
import GoBack from 'components/general/go_back.js';
import Loading from 'components/general/loading.js';
import BettingHistoryItem from './BettingHistoryItem.js';
import NoItem from './NoItem.js';
import Calendar from 'components/profile/calendar.js';
import Paginate from 'components/general/pagination.js';
import {
  getBettingHistoryWonReq,
  getBettingHistoryLostReq,
  getBettingHistoryCashoutReq
} from 'stores/auth/services';
import { BetSlipQuerySortBy_DatePlaced_Des } from 'toolkit/constants.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';

export default function BettingHistory() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const [bettingHistory, setBettingHistory] = useState({ data: [], meta: null });
  const tabMenuItems = [
    {
      id: 2,
      key: 'won'
    },
    {
      id: 3,
      key: 'lost'
    },
    {
      id: 4,
      key: 'cashout'
    }
  ];
  const [betStatusTypeForBettingHistoryTab, setBetStatusTypeForBettingHistoryTab] = useState(
    tabMenuItems[0]
  );
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({
    startDate: formatISO(startOfMonth(new Date())),
    endDate: formatISO(new Date())
  });
  const [loading, setLoading] = useState(false);

  const getBettingHistoryParams = () => ({
    pageNumber: 0,
    pageSize: 10,
    startDate: date.startDate,
    endDate: date.endDate,
    sort: BetSlipQuerySortBy_DatePlaced_Des
  });

  const handleGetMyHistoryBets = async (status, params) => {
    try {
      setLoading(true);
      let response = { data: [], meta: null };
      switch (status) {
        case 'won':
          response = await getBettingHistoryWonReq(params);
          break;
        case 'lost':
          response = await getBettingHistoryLostReq(params);
          break;
        case 'cashout':
          response = await getBettingHistoryCashoutReq(params);
          break;
        default:
          console.error('Invalid betStatusType');
          break;
      }
      setBettingHistory({
        data: response.data,
        meta: response.metaData
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleGetMyHistoryBets(betStatusTypeForBettingHistoryTab.key, getBettingHistoryParams());
  }, [date, betStatusTypeForBettingHistoryTab]);

  const handleDateChange = (start, end) => {
    if (start === null || end === null) {
      showToaster(t('pages.bettinghistory.messageDateRangeError'), 'error');
      return;
    }
    setDate({
      startDate: formatISO(start),
      endDate: formatISO(end)
    });
    setOpen(false);
  };

  const onPaginate = (pageIndex) => {
    handleGetMyHistoryBets(betStatusTypeForBettingHistoryTab.key, {
      ...getBettingHistoryParams(),
      pageNumber: pageIndex
    });
  };

  if (loading) {
    return <Loading message={t('pages.bettinghistory.messageLoading')} />;
  }

  const onTabChange = (tabMenuItem) => {
    setBetStatusTypeForBettingHistoryTab(tabMenuItem);
    handleGetMyHistoryBets(tabMenuItem.key, getBettingHistoryParams());
  };

  return (
    <Container fluid>
      <Row className="mt-75">
        <Col xs={1}>
          <TitleHistoryBack
            title={t('pages.bettinghistory.header.title')}
            subTitle={t('pages.bettinghistory.header.subtitle')}
          />
        </Col>
        <Col className="text-end fs-8">
          {date.startDate !== null && date.endDate !== null && (
            <div>
              <span className="text-muted">
                {dayjs(date.startDate).format('DD/MM/YYYY')} -{' '}
                {dayjs(date.endDate).format('DD/MM/YYYY')}
              </span>
            </div>
          )}
          <Button
            variant="link"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}>
            <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/76.png`} />
          </Button>
        </Col>
      </Row>
      <Collapse in={open}>
        <div>
          <Calendar onCalendarSubmit={handleDateChange} initialDate={date} />
        </div>
      </Collapse>
      <hr className="mt-n3 mb-3" />
      {/* {location.state === undefined ? (
        <></>
      ) : (
        <Row>
          <Col xs={5} className="top-header-left">
            From:
            {format(location.state.start, 'dd MMM yyyy', { locale: enGB })}
          </Col>
          <Col xs={5} className="top-header-left">
            To: {format(location.state.end, 'dd MMM yyyy', { locale: enGB })}
          </Col>
        </Row>
      )} */}
      <ul className="nav nav-pills nav-fill nav-warning mb-3">
        {tabMenuItems.map((tabMenuItem) => (
          <li className="nav-item" key={tabMenuItem.id}>
            <button
              className={`nav-link fs-6 text-dark p-1 ${
                betStatusTypeForBettingHistoryTab.id === tabMenuItem.id && 'active'
              }`}
              onClick={() => onTabChange(tabMenuItem)}>
              {t(`pages.bettinghistory.tabmenu.${tabMenuItem.key}`)}
            </button>
          </li>
        ))}
      </ul>
      {/* <Row>
        <Col xs={4}>
          <div
            onClick={() => setSelectedBetSlipType('won')}
            className={
              betStatusType !== 'won'
                ? 'round'
                : 'round selected-background'
            }
          >
            <span>{t('wonbets')}</span>
          </div>
        </Col>
        <Col xs={4}>
          <div
            onClick={() => setSelectedBetSlipType('cashout')}
            className={
              betStatusType !== 'cashout'
                ? 'round'
                : 'round selected-background'
            }
          >
            <span>{t('cashout')}</span>
          </div>
        </Col>
        <Col xs={4}>
          <div
            onClick={() => setSelectedBetSlipType('lost')}
            className={
              betStatusType !== 'lost'
                ? 'round'
                : 'round selected-background'
            }
          >
            <span>{t('lostbets')}</span>
          </div>
        </Col>
      </Row> */}
      {bettingHistory.data.length === 0 ? (
        <NoItem
          message={t(
            `pages.bettinghistory.message_no_${betStatusTypeForBettingHistoryTab.key}_bets`
          )}
        />
      ) : (
        bettingHistory.data.map((bettingHistoryItem, index) => (
          <BettingHistoryItem
            key={index}
            bettingHistoryItem={bettingHistoryItem}
            tab={betStatusTypeForBettingHistoryTab.key}
          />
        ))
      )}
      <Paginate response={bettingHistory} onPaginate={onPaginate} />
    </Container>
  );
}
