import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GoBack from '../general/go_back';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function Bank() {
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <Container>
      <Row>
        <Col xs={1} className="mt5">
          <GoBack location="/profile" />
        </Col>
        <Col xs={2} className="float-left ">
          <div className="mt10"></div>
          <div className="float-left fweight-bold fsize-14">{t('bank')}</div>
          <div className="float-left">{t('profile')}</div>
        </Col>
      </Row>
      <div className="mt10"></div>
      <hr className="m-0"></hr>
      <div className="mt15"></div>
      <Row>
        <Col xs={1}>
          <img className="float-left" src={`${AWS_BUCKET_URL}/Icons/123.png`}></img>
        </Col>
        <Col xs={4}>
          <Link to="/transactions" className="float-left fsize-14">
            {t('myfinance')}
          </Link>
        </Col>
      </Row>
      <div className="mt10"></div>
      <hr className="m-0"></hr>
      <div className="mt10"></div>
      <Row>
        <Col xs={1}>
          <img className="float-left" src={`${AWS_BUCKET_URL}/Icons/124.png`}></img>
        </Col>
        <Col xs={3}>
          <Link to="/payout" className="float-left fsize-14 ">
            {t('payout')}
          </Link>
        </Col>
      </Row>
      <div className="mt10"></div>
      <hr className="m-0"></hr>
      <></>
      <></>
    </Container>
  );
}
