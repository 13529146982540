import React, { useRef, useState } from 'react';
import './match_tracker_theme.css';


const MatchTracker = (props) => {
  const { matchId, sportId, language, selectedTabCallbackFunc } = props;
  const [error, setError] = useState(false);

  // const setIframeHeightOnLoad = () => {
  //   const iframe = document.getElementById('footballFrame');
  //   if (iframe) {
  //     iframe.style.height = `${window.innerHeight - 510}px`;
  //   }
  // };

  return matchId != '0' && matchId != '' && !error ? (
    <div>
      {/* {loading && <Loading message="" />}
      <div
        ref={matchTrackerRef}
        className={`matchtracker sr-widget sr-widget-1 ${loading ? 'd-none' : ''}`}></div> */}
      <iframe
        src={`https://trackerv2.yextof.com/preview.php?55&id=${matchId}&token=f28030ff1a1528e1fcc991975d76b8f0&lang=${language}`}
        id="footballFrame"
        height="420"
        width="100%"></iframe>
    </div>
  ) : null;
};

export default MatchTracker;
