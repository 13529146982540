import { EBetGroupName } from './Enums';
export const COOKIE_KEYS = { LANGUAGE_CODE: 'LANGUAGE_CODE' };

export const TMM_URL = process.env.REACT_APP_TMM_URL;
export const BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL;
export const AWS_BUCKET_URL = process.env.REACT_APP_AWS_BUCKET_URL;

export const HomeIcon = `${AWS_BUCKET_URL}/Icons/home.svg`;

export const betType = [
  { code: 1, description: 'Winner' },
  { code: 8, description: 'First Goal' },
  { code: 9, description: 'Last Goal' },
  { code: 10, description: 'Double Chance' },
  { code: 14, description: 'Handicap' },
  { code: 18, description: 'Over/Under' },
  { code: 4, description: 'Next Goal' },
  { code: 5, description: '1st Halftime' },
  { code: 6, description: '1st Halftime Handicap' },
  { code: 7, description: '1st Halftime Over/Under' },
  { code: 8, description: '1st Halftime Next Goal' },
  { code: 10, description: '1st Halftime Double Chance' },
  { code: 11, description: 'Both To Score' },
  { code: 12, description: '1st Halftime Both To Score' },
  { code: 13, description: 'Draw no bet' },
  { code: 14, description: '1st Halftime Draw no bet' }
];

export const BetGroups = {
  [EBetGroupName.TOP_BETS]: {
    tabOrder: 1,
    markets: [
      1, 2, 3, 4, 7, 8, 10, 11, 14, 16, 18, 9, 52, 25, 29, 41, 47, 112, 113, 114, 115, 116, 123,
      186, 187, 189, 201, 202, 204, 219, 223, 225, 234, 235, 236, 251, 272, 292, 296, 303, 897, 340,
      499, 1055, 1056
    ]
  },
  [EBetGroupName.Over_Under]: {
    tabOrder: 2,
    markets: [
      18, 19, 20, 58, 59, 68, 69, 70, 90, 91, 92, 225, 227, 228, 258, 260, 261, 238, 494, 756, 757
    ]
  },
  [EBetGroupName.FIRST_HALF_TIME]: {
    tabOrder: 3,
    markets: [60, 61, 62, 63, 64, 65,66, 68, 69, 70, 71, 72, 73, 74, 75, 80, 174, 176, 177, 552]
  },
  [EBetGroupName.SECOND_HALF_TIME]: {
    tabOrder: 4,
    markets: [83, 84, 85, 86, 90, 91, 92, 94, 95]
  },
  [EBetGroupName.HANDICAP]: {
    tabOrder: 5,
    markets: [14, 16, 65, 66, 187, 188, 203, 223, 237, 256,303, 493]
  },

  [EBetGroupName.CORNER]: {
    tabOrder: 6,
    markets: [162, 166]
  },
  [EBetGroupName.GOALS]: {
    tabOrder: 7,
    markets: [8, 9, 21, 23, 24, 25, 26, 29, 30, 33, 34, 551]
  },
  [EBetGroupName.PLAYER]: {
    tabOrder: 8,
    markets: [38, 39, 40, 775, 888, 891, 892, 898, 900, 901]
  },
  [EBetGroupName.DOUBLE_BETS]: {
    tabOrder: 9,
    markets: [35, 36, 37, 47, 78, 79, 540, 542, 546, 547]
  },
  [EBetGroupName.TRIPLE_BETS]: { tabOrder: 10, markets: [818, 819, 820] },
  [EBetGroupName.SPECIAL_BETS]: {
    tabOrder: 100,
    markets: [5, 6, 48, 49, 50, 51, 52, 55, 56, 57, 100, 122, 199, 207]
  }
};

export const TopLeagues = [
  'sr:category:4',
  'sr:category:393',
  'sr:category:392',
  'sr:category:1',
  'sr:category:30',
  'sr:category:32',
  'sr:category:31',
  'sr:category:7',
  'sr:category:46',
  'sr:category:35',
  'sr:category:44',
  'sr:category:33',
  'sr:category:21'
  // 'tp:category:1962',
  // 'tp:category:2185',
  // 'tp:category:1934',
  // 'tp:category:1952',
  // 'tp:category:1960',
  // 'tp:category:1954',
  // 'tp:category:1955',
  // 'tp:category:1956',
  // 'tp:category:1959',
  // 'tp:category:1919',
  // 'tp:category:1953',
  // 'tp:category:2257'
  // turkey italy france missing
];

export const TopTournaments = [
  'sr:tournament:7' /*UEFA Champions League*/,
  'sr:tournament:679' /*UEFA Europa League*/,
  'sr:tournament:34480' /*UEFA Europa Conference League*/,
  'sr:tournament:23755' /*UEFA Nations League*/,
  'sr:tournament:465' /*UEFA SuperCup*/,
  'sr:tournament:16' /*World Cup*/,
  'sr:tournament:11' /*World Cup Qualification UEFA*/,
  'sr:tournament:1' /*EURO Cup*/,
  'sr:tournament:27' /*EURO Cup Qualification*/,
  'sr:tournament:133' /*Copa America*/,
  'sr:tournament:17' /*Premier League*/,
  'sr:tournament:19' /*England FA CUP*/,
  'sr:tournament:18' /*England Championship*/,
  'sr:tournament:18' /*England Championship Playoffs*/,
  'sr:tournament:346' /*England Community Shield*/,
  'sr:tournament:19' /*England FA Cup*/,
  'sr:tournament:23' /*Serie A*/,
  'sr:tournament:34' /*Ligue 1*/,
  'sr:tournament:339' /*France Trophee Des Champions*/,
  'Sr:tournament:35' /*Bundesliga*/,
  'sr:tournament:35' /*Bundesliga, Relegation/Promotion Playoffs*/,
  'sr:tournament:217' /*Germany DFB Pokal*/,
  'sr:tournament:44' /*Germany 2. Bundesliga*/,
  'sr:tournament:44' /*Germany 2. Bundesliga Relegation/Promotion Playoffs*/,
  'sr:tournament:799' /*Germany Supercup*/,
  'sr:tournament:217' /*Germany DFB Pokal*/,
  'sr:tournament:8' /*LaLiga*/,
  'sr:tournament:329' /*La Liga Copa Del Rey*/,
  'sr:tournament:52' /*Turkey Super Lig*/,
  'sr:tournament:96' /*Turkey Cup*/,
  'sr:tournament:213' /*Spain SuperCopa*/,
  'sr:tournament:328' /*Copa Italia*/,
  'sr:tournament:341' /*Italy Supercup*/,
  'sr:tournament:238' /*Portugal Primeira Liga*/,
  'sr:tournament:238' /*Portugal Primeira Liga Relegation/Promotion*/,
  'sr:tournament:37' /*Netherlands Eredivisie*/,
  'Sr:tournament:37' /*Netherlands Eredivisie Europa Playoffs*/,
  'sr:tournament:37' /*Netherlands Eredivisie Relegation/Promotion Playoffs*/,
  'sr:tournament:340' /*Johan Cruijff Schaal*/,
  'sr:tournament:132' /*NBA*/,
  'sr:tournament:878' /*International Woman Qualifying UEFA*/,
  'sr:tournament:851' /*INTERNATIONAL FRIENDLY GAMES*/,
  'sr:tournament:155' /* Argentina Superliga*/,
  'sr:tournament:325' /* Brasiliero Seri A*/
  // 'tp:tournament:1775722' /*UEFA SuperCup*/,
  // 'tp:tournament:1806222' /*UEFA Champions League*/,
  // 'tp:tournament:1806261' /*UEFA Europa League*/,
  // 'tp:tournament:1806587' /*UEFA Europa Conference League*/,
  // 'tp:tournament:1771470' /*Premier League*/,
  // 'tp:tournament:1778401' /*England Championship*/,
  // //'tp:tournament:1778401' /*England Championship Playoffs*/,
  // 'tp:tournament:1775720' /*England Community Shield*/,
  // 'tp:tournament:1785995' /*Serie A*/,
  // 'tp:tournament:1784448' /*Ligue 1*/,
  // 'tp:tournament:1783234' /*Bundesliga*/,
  // //'tp:tournament:1783234' /*Bundesliga, Relegation/Promotion Playoffs*/,
  // 'tp:tournament:1783941' /*Germany 2. Bundesliga*/,
  // //'tp:tournament:1783941' /*Germany 2. Bundesliga Relegation/Promotion Playoffs*/,
  // 'tp:tournament:1772014' /*Germany Supercup*/,
  // 'tp:tournament:1790775' /*Germany DFB Pokal*/,
  // 'tp:tournament:1782690' /*LaLiga*/,
  // 'tp:tournament:1795212' /*Portugal Primeira Liga*/,
  // //'tp:tournament:1795212' /*Portugal Primeira Liga Relegation/Promotion*/,
  // 'tp:tournament:1788619' /*Portugal Super Cup*/,
  // 'tp:tournament:1786102' /*Netherlands Eredivisie*/,
  // //'tp:tournament:1786102' /*Netherlands Eredivisie Europa Playoffs*/,
  // //'tp:tournament:1786102' /*Netherlands Eredivisie Relegation/Promotion Playoffs*/,
  // 'tp:tournament:1785981' /*Johan Cruijff Schaal*/,
  // 'tp:tournament:1796216' /*UEFA League Qualification*/,
  // 'tp:tournament:1783190' /*UEFA Europa Conference League Qualification*/,
  // 'tp:tournament:1783082' /*UEFA Champions League Qualification*/,
  // //"tp:tournament:1786622" /*Copa Libertadores*/,
  // //"tp:tournament:1784833" /*Copa Sudamericana*/,
  // 'tp:tournament:1795597' /*Turkey Super Lig*/,
  // 'tp:tournament:1766351' /*EURO Cup Qualification*/,
  // 'tp:tournament:1812259' /*NBA*/
];

export const TopLeaguesImages = [
  // {
  //   leagueName: 'EURO',
  //   imageName: `${AWS_BUCKET_URL}/Leagues/euro.png`,
  //   betradarCategoryId: 'sr:tournament:1'
  // },
  // {
  //   leagueName: 'Copa America',
  //   imageName: `${AWS_BUCKET_URL}/Leagues/copaamerica.png`,
  //   betradarCategoryId: 'sr:tournament:133'
  // },
  // {
  //   leagueName: 'Libertadores',
  //   imageName: `${AWS_BUCKET_URL}/Leagues/libertadores.png`,
  //   betradarCategoryId: 'sr:tournament:384'
  // },
  // {
  //   leagueName: 'Sudamericana',
  //   imageName: `${AWS_BUCKET_URL}/Leagues/sudamericana.png`,
  //   betradarCategoryId: 'sr:tournament:480'
  // },
  // {
  //   leagueName: 'Argentina',
  //   imageName: `${AWS_BUCKET_URL}/ClientFlags/48.svg`,
  //   betradarCategoryId: 'sr:tournament:155'
  // },
  // {
  //   leagueName: 'Brasil',
  //   imageName: `${AWS_BUCKET_URL}/ClientFlags/13.svg`,
  //   betradarCategoryId: 'sr:tournament:325'
  // },
  // {
  //   leagueName: 'USA',
  //   imageName: `${AWS_BUCKET_URL}/ClientFlags/26.svg`,
  //   betradarCategoryId: 'sr:tournament:28163'
  // },
  // {
  //   leagueName: 'Japan',
  //   imageName: `${AWS_BUCKET_URL}/ClientFlags/52.svg`,
  //   betradarCategoryId: 'sr:tournament:196'
  // }
  {
    leagueName: 'Champions League',
    imageName: `${AWS_BUCKET_URL}/Leagues/championsleague.png`,
    betradarCategoryId: 'sr:tournament:7'
  },
  {
    leagueName: 'Europa League',
    imageName: `${AWS_BUCKET_URL}/Leagues/europaleague.png`,
    betradarCategoryId: 'sr:tournament:679'
  },
  {
    leagueName: 'Conference League',
    imageName: `${AWS_BUCKET_URL}/Leagues/conferenceleague.png`,
    betradarCategoryId: 'sr:tournament:34480'
  },
  {
    leagueName: 'Premier League',
    imageName: `${AWS_BUCKET_URL}/Leagues/premier-league1.png`,
    betradarCategoryId: 'sr:tournament:17'
  },
  {
    leagueName: 'Bundesliga',
    imageName: `${AWS_BUCKET_URL}/Leagues/bundesliga1.png`,
    betradarCategoryId: 'sr:tournament:35'
  },
  {
    leagueName: 'Turkish Super Lig',
    imageName: `${AWS_BUCKET_URL}/Leagues/sportotosuperlig.png`,
    betradarCategoryId: 'sr:tournament:52'
  },
  {
    leagueName: 'Ligue 1',
    imageName: `${AWS_BUCKET_URL}/Leagues/ligue1icon.png`,
    betradarCategoryId: 'sr:tournament:34'
  },
  {
    leagueName: 'La Liga',
    imageName: `${AWS_BUCKET_URL}/Leagues/primera-division1.png`,
    betradarCategoryId: 'sr:tournament:8'
  },
  {
    leagueName: 'Serie A',
    imageName: `${AWS_BUCKET_URL}/Leagues/seriea.png`,
    betradarCategoryId: 'sr:tournament:23'
  },
  {
    leagueName: 'Eredivisie',
    imageName: `${AWS_BUCKET_URL}/Leagues/eredivisie.png`,
    betradarCategoryId: 'sr:tournament:37'
  },
  {
    leagueName: 'Portugal',
    imageName: `${AWS_BUCKET_URL}/Leagues/ligaportugal.png`,
    betradarCategoryId: 'sr:tournament:238'
  }
];

export const BetSlipQuerySortBy_DatePlaced_Des = 1;
export const BetSlipQuerySortBy_DatePlaced_Asc = 2;
export const BetSlipQuerySortBy_StakeAtPlaceBet_Des = 3;
export const BetSlipQuerySortBy_StakeAtPlaceBet_Asc = 4;

export const popularGamesId = [
  '5048',
  '5050',
  '5072',
  '5047',
  '5817',
  '304598',
  '304697',
  '5053',
  '5040',
  '304182',
  '304589',
  '304423'
];

export function calculateMatchCountByLeagues(data, category) {
  let sayi = 0;
  let tournamentsArray = [];

  if (data != null) {
    tournamentsArray.push(data[category]);
  }

  if (tournamentsArray != []) {
    for (let index = 0; index < tournamentsArray.length; index++) {
      const tournamentValues = Object.values(tournamentsArray[index]);
      tournamentValues.forEach((element) => {
        sayi += element;
      });
    }
  }

  return sayi > 0 ? sayi : '-';
}

export function calculateSportCount(sportsCountData, sportType) {
  let number = 0;
  let categoryList = [];
  let tournamentsArray = [];

  if (
    sportsCountData != null &&
    sportsCountData != undefined &&
    sportsCountData[sportType] != null &&
    sportsCountData[sportType] != undefined
  ) {
    Object.keys(sportsCountData[sportType]).map((category) => {
      categoryList.push(category);
    });
  }

  if (categoryList != []) {
    categoryList.forEach((cat) => {
      tournamentsArray.push(sportsCountData[sportType][cat]);
    });
  }

  if (tournamentsArray != []) {
    for (let index = 0; index < tournamentsArray.length; index++) {
      const tournamentValues = Object.values(tournamentsArray[index]);
      tournamentValues.forEach((element) => {
        number += element;
      });
    }
  }
  return number > 0 ? number : 0;
}

export const TopSports = ['Football', 'Basketball', 'Tennis', 'IceHockey', 'Handball'];

export const OtherSports = [
  'Volleyball',
  'Futsal',
  'Rugby',
  'Snooker',
  'Baseball',
  'Boxing',
  'Darts',
  'TableTennis',
  'AmericanFootball',
  'Cricket',
  'LeagueOfLegends',
  'CounterStrike',
  'Dota2',
  'EFootball'
];
export const sports = {
  Football: 0,
  Basketball: 1,
  Tennis: 2,
  AmericanFootball: 3,
  Baseball: 4,
  Cricket: 5,
  Darts: 6,
  Futsal: 7,
  Handball: 8,
  IceHockey: 9,
  Rugby: 10,
  Snooker: 11,
  TableTennis: 12,
  Volleyball: 13,
  Boxing: 14,
  Other: 999
};

export const getSportName = (sportId) => Object.keys(sports).find((key) => sports[key] === sportId);

export const getBetGroups = (marketId) => {
  const betGroups = [];

  for (const key in BetGroups) {
    if (BetGroups[key].markets.includes(marketId)) {
      betGroups.push(key);
    }
  }

  if (betGroups.length) {
    return betGroups;
  }

  return null;
};

// 1 X 2
export const tripleBets = [1, 7, 8, 9, 14, 60, 61, 62, 65, 83, 84, 113, 114, 162, 235, 491];
// 1 2
export const doubleBets = [
  2, 11, 64, 86, 186, 202, 219, 251, 340, 499, 16, 66, 187, 188, 203, 223, 237, 256, 327, 493
];
// + -
export const overUnderBets = [
  18, 19, 20, 68, 69, 70, 90, 91, 92, 116, 166, 189, 225, 236, 238, 258, 310, 314, 328, 494, 605
];

export const TableNames = {
  1: 'Bulgaria Roulette',
  3: 'Bulgaria Baccarat',
  16: 'VIP Blackjack 1',
  17: 'Macau Baccarat',
  18: 'VIP Blackjack 2',
  36: 'French Roulette',
  43: 'Las Vegas Roulette',
  160: 'Baccarat Galaxy 1',
  162: 'Baccarat Galaxy 3',
  167: 'Roulette Galaxy 1',
  168: 'Roulette American Auto',
  182: 'American Roulette',
  183: 'Portomaso Roulette',
  219: 'VIP Blackjack 4',
  222: 'Las Vegas Blackjack',
  229: 'Roulette Auto',
  232: 'Chile Roulette VIP',
  244: 'Oracle Blaze Roulette',
  245: 'Oracle 360 Roulette',
  281: 'Dragon Tiger',
  344: 'Las Vegas Roulette 1',
  348: 'Las Vegas Baccarat 1',
  349: 'Las Vegas Baccarat 2',
  350: 'Las Vegas Baccarat 3',
  351: 'Singapore Baccarat 1',
  352: 'Singapore Baccarat 2',
  353: 'Singapore Baccarat 3',
  354: 'Montecarlo Baccarat 1',
  355: 'Montecarlo Baccarat 2'
};

export const tournamentGroups = {
  7: [7],
  679: [679],
  34480: [34480],
  8: [8, 54],
  54: [54, 8],
  17: [17, 18, 24, 25],
  18: [18, 17, 24, 25],
  24: [24, 17, 18, 25],
  25: [25, 17, 18, 24],
  23: [23, 53],
  53: [53, 23],
  34: [34, 182],
  182: [182, 34],
  37: [37, 113],
  113: [113, 37],
  339: [339, 34],
  35: [35, 44, 491],
  44: [44, 35, 491],
  491: [491, 35, 44],
  52: [52, 98],
  98: [98, 52],
  238: [238, 239],
  239: [239, 238]
};

//create a object array with tournaments name and ids

export const tournamentNames = {
  7: 'Champions League',
  679: 'Europa League',
  34480: 'Conference League',
  8: 'LaLiga',
  54: 'La Liga 2',
  17: 'Premier League',
  18: 'Championship',
  24: 'League One',
  25: 'League Two',
  23: 'Serie A',
  34: 'Ligue 1',
  35: 'Bundesliga',
  44: '2.Bundesliga',
  491: '3.Bundesliga',
  52: 'Super Lig',
  53: 'Serie B',
  96: 'Turkey Cup',
  182: 'Ligue 2',
  238: 'Primeira Liga',
  37: 'Eredivisie',
  113: 'Eerste Divisie',
  239: 'Liga Portugal 2',
  98: '1.Lig'
};
