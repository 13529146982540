import { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchSportCounts } from 'stores/match/index.js';
import {
  calculateTimeParameter,
  getSportTypeToActiveIcon,
  getSportTypeToWhiteIcon
} from 'toolkit/utils';
import { calculateSportCount, calculateMatchCountByLeagues, TopLeagues } from 'toolkit/constants';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import SportDetailItem from 'components/general/SportDetailItem.js';
import SportListItem from 'components/sport/sport_list_item.js';

export default function SportCategories() {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();
  const { sportType } = useParams();
  const [searchParams] = useSearchParams();
  const time = searchParams.get('time');
  const showSportMenu = searchParams.get('show');
  const [selectedSportType, setSelectedSportType] = useState(sportType ? sportType : 'Football');
  const counts = useSelector((state) => {
    if (sportType) {
      return state.match.counts;
    }
    return state.match.timeCounts;
  });
  const categoryNames = useSelector((state) => state.match.categoryNames);

  const getTimeHeading = () => {
    if (time) {
      if (time.includes(' ')) {
        return time.replaceAll(' ', '')[0].toLocaleLowerCase() + time.replaceAll(' ', '').slice(1);
      } else {
        return time[0].toLowerCase() + time.slice(1);
      }
    }
  };

  const getData = async () => {
    try {
      if (sportType) return;
      dispatch(fetchSportCounts({ dateFilter: calculateTimeParameter(time) }));
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function getLeagues(leagues, leagueName) {
    if (leagues.length > 0) {
      return (
        <Fragment key={leagueName}>
          <h6 className="text-align-justify">{leagueName}</h6>
          <div>{leagues}</div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  // sport buttons
  function calculateMenuItemsDynamic() {
    const menuItems = [];
    let icon = '';
    if (counts && counts[selectedSportType]) {
      Object.keys(counts).forEach((sportType) => {
        if (sportType === selectedSportType) {
          icon = getSportTypeToActiveIcon(selectedSportType);
        } else {
          icon = getSportTypeToWhiteIcon(sportType);
        }
        const matchCount = calculateSportCount(counts, sportType);
        if (matchCount > 0) {
          menuItems.push(
            <SportDetailItem
              key={sportType}
              active={selectedSportType === sportType}
              count={matchCount}
              icon={icon}
              onClick={() => {
                setSelectedSportType(sportType);
              }}
              text={t(`sportType.${sportType}`)}
              className={'d-flex flex-column gap-2'}
            />
          );
        }
      });
      return menuItems;
    }
  }

  function leagueNames() {
    const topLeagues = [];
    const azLeagues = [];
    const leagueArray = [];

    if (counts[selectedSportType]) {
      Object.keys(counts[selectedSportType])
        .sort((a, b) => categoryNames[a]?.localeCompare(categoryNames[b]))
        .forEach((category) => {
          const sportListItem = (
            <SportListItem
              key={category}
              to={`/sport-details/${selectedSportType}/matches?category=${category}${
                time ? `&time=${time}` : ''
              }`}
              count={calculateMatchCountByLeagues(counts[selectedSportType], category)}
              itemName={category}
              text={t(`categories.${category}`)}
              topLeagueIndex={TopLeagues.indexOf(category)}
            />
          );
          if (TopLeagues.includes(category)) {
            topLeagues.push(sportListItem);
          } else {
            azLeagues.push(sportListItem);
          }
        });
    }
    leagueArray.push(
      getLeagues(
        topLeagues.sort((t1, t2) => t1.props.topLeagueIndex - t2.props.topLeagueIndex),
        t('topLeagues')
      ),
      getLeagues(azLeagues, t('azLeagues'))
    );
    return leagueArray;
  }
  function getAllTab() {
    if (
      (time !== 'Next 4 Hours' &&
        time !== 'Today' &&
        time !== 'Tomorrow' &&
        time !== 'Upcoming Live Matches') ||
      !counts[selectedSportType]
    )
      return null;
    return (
      <SportListItem
        to={`/sport-details/${selectedSportType}/matches?category=all${
          time ? `&time=${time}` : ''
        }`}
        count={Object.values(counts[selectedSportType])
          .map((c) => Object.values(c))
          .flatMap((c) => c)
          .reduce((partialSum, a) => partialSum + a, 0)}
        text={t(`allcategories`)}
      />
    );
  }

  return (
    <div className="container">
      {showSportMenu && (
        // menu
        <div className="overflow-auto text-nowrap mb-2 bg-dark mx-n3 d-flex">
          {calculateMenuItemsDynamic()}
        </div>
      )}
      <TitleHistoryBack
        title={t('sport')}
        subTitle={
          sportType
            ? t(`sportType.${sportType}`)
            : t(`pages.desktop.filterSidebar.${getTimeHeading()}`)
        }
      />
      {getAllTab()}
      <div>{leagueNames()}</div>
    </div>
  );
}
