import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatISO, subWeeks } from 'date-fns';
import Loading from 'components/general/loading.js';
import BettingHistoryItem from 'components/profile/bettingHistory/BettingHistoryItem.js';
import NoItem from 'components/profile/bettingHistory/NoItem.js';
import Paginate from 'components/general/pagination.js';
import { BetSlipQuerySortBy_DatePlaced_Des } from 'toolkit/constants';
import { fetchLiveMatches } from 'stores/match';
import { getMyOpenBetsReq, getMyClosedBetsReq } from 'stores/auth/services';

export default function MyBets() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations', { useSuspense: false });

  const tabMenuItems = [
    {
      id: 1,
      key: 'open'
    },
    {
      id: 2,
      key: 'closed'
    }
  ];
  const [betStatusTypeForMyBetsTab, setBetStatusTypeForMyBetsTab] = useState(tabMenuItems[0]);
  const [myBets, setMyBets] = useState({
    data: [],
    meta: null
  });
  const [loading, setLoading] = useState(true);

  const date = {
    startDate: formatISO(subWeeks(new Date(), 4)),
    endDate: formatISO(new Date().setUTCHours(23, 59, 59, 999))
  };

  const getData = async () => {
    try {
      // setLoading(true);
      // await dispatch(fetchLiveMatches()).unwrap();
      dispatch(fetchLiveMatches());
    } catch (error) {
      // error
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getMyBetsParams = () => ({
    pageNumber: 0,
    pageSize: 25,
    startDate: date.startDate,
    endDate: date.endDate,
    sort: BetSlipQuerySortBy_DatePlaced_Des
  });

  const handleGetMyBets = async (status, params) => {
    try {
      setLoading(true);
      let response = { data: [], meta: null };
      if (status === 'open') {
        const { pageNumber, pageSize } = params;
        response = await getMyOpenBetsReq({ pageNumber, pageSize });
      } else if (status === 'closed') {
        response = await getMyClosedBetsReq(params);
      } else {
        console.error('Invalid betStatusType');
      }
      setMyBets({
        data: response.data,
        meta: response.metaData
      });
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleGetMyBets(betStatusTypeForMyBetsTab.key, getMyBetsParams());
  }, []);

  const onPaginate = (pageIndex) => {
    handleGetMyBets(betStatusTypeForMyBetsTab.key, { ...getMyBetsParams(), pageNumber: pageIndex });
  };

  const onTabChange = (tabMenuItem) => {
    setBetStatusTypeForMyBetsTab(tabMenuItem);
    handleGetMyBets(tabMenuItem.key, getMyBetsParams());
  };

  if (loading) {
    return <Loading message={t('pages.mybets.messageLoading')} />;
  }

  return (
    <Container fluid className="mybets g-2">
      <div className="mt10" />
      <ul className="nav nav-pills nav-fill nav-warning mb-3">
        {tabMenuItems.map((tabMenuItem) => (
          <li className="nav-item" key={tabMenuItem.id}>
            <button
              className={`nav-link fs-17 text-dark p-1 ${
                betStatusTypeForMyBetsTab.id === tabMenuItem.id && 'active'
              }`}
              onClick={() => onTabChange(tabMenuItem)}>
              {t(`pages.mybets.tabmenu.${tabMenuItem.key}`)}
            </button>
          </li>
        ))}
      </ul>
      {myBets.data.length === 0 ? (
        <NoItem message={t(`pages.mybets.message_no_${betStatusTypeForMyBetsTab.key}_bets`)} />
      ) : (
        myBets.data.map((bettingHistoryItem, index) => (
          <BettingHistoryItem key={index} bettingHistoryItem={bettingHistoryItem} />
        ))
      )}
      <Paginate response={myBets} onPaginate={onPaginate} />
    </Container>
  );
}
