import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, FormControl, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GoBack from '../general/go_back';
import { errorToaster, showToaster } from 'mixin/general';
import FetchButton from 'components/general/FetchButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'stores/auth';
import { setTotal } from 'stores/betSlip';

export default function ProfileSettings() {
  const dispatch = useDispatch();
  const { user, userSettings } = useSelector((state) => state.auth);
  const { t } = useTranslation('translations');
  // const [stake, setStake] = useState(10);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    defaultStake: 10,
    presetStake1: 10,
    presetStake2: 20,
    presetStake3: 30,
    presetStake4: 40,
    presetStake5: 50
  });

  // const Increment = () => {
  //   setStake(stake + 1);
  // };

  // const Decrement = () => {
  //   setStake(stake - 1);
  // };

  useEffect(() => {
    dispatch(setTotal(formData.defaultStake));
  }, [formData.defaultStake]);

  useEffect(() => {
    if (userSettings && user) {
      const userData = { ...formData };
      Object.keys(userSettings).forEach((field) => {
        if (Object.keys(userData).indexOf(field) > -1 && userSettings[field]) {
          userData[field] = userSettings[field];
        }
      });
      if (user.id) {
        userData.id = user.id;
      }
      setFormData({ ...userData });
    }
  }, [userSettings, user]);

  const handleFormDataChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error('Please fill all required fields');
  };

  const handleEditSettings = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await dispatch(updateSettings(formData)).unwrap();
      if (response.statusCode !== 200) {
        throw response.errors;
      }
      showToaster(t('pages.settings.successMessageSettings'), 'success');
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  return (
    <section className="py-2 text-start">
      <Container>
        <Form noValidate validated={validated} onSubmit={handleEditSettings}>
          <Row>
            <Col xs={1} className="mt5">
              <GoBack />
            </Col>
            <Col>
              <div className="float-left mt-3">{t('myprofile')}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="float-left mt-3">{t('profilesettings')}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray">{t('general')}</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray">{t('language')}</h6>
              <h6 className="float-right fade-text-gray">English</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray">{t('oddsdisplay')}</h6>
              <h6 className="float-right fade-text-gray font-size-14">European Odds</h6>
            </Col>
          </Row>
          <hr className="m-0"></hr>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray">{t('sportsbook')}</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left font-size-14">{t('ordermatchesbytime')}</h6>
              <Form.Check type="switch" id="custom-switch-show-balance" className="float-right" />
            </Col>
          </Row>
          <hr className="m-0"></hr>
          <>
            <Row>
              <Col>
                <h6 className="float-left fade-text-gray">{t('notifications')}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="float-left">{t('notifications')}</h6>
                <div className="float-right">
                  <Form.Check type="switch" id="custom-switch-show-balance" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="float-left">
                  <h6 className="color-blue">{t('resetallnotifications')}</h6>
                </div>
              </Col>
            </Row>
          </>
          <>
            <Row>
              <Col>
                <h6 className="float-left fade-text-gray">{t('oddschanges')}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="float-left">{t('acceptoddschanges')}</h6>
                <div className="float-right">
                  <Form.Check type="switch" id="custom-switch-show-balance" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="float-left">
                  <h6 className="fade-text-gray">{t('highestoddsarealwaysaccepted')}</h6>
                </div>
              </Col>
            </Row>
          </>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray">{t('stakesettings')}</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left">{t('standartstake')} (EUR)</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                placeholder={t('standartstake')}
                value={formData.defaultStake}
                onChange={(e) => handleFormDataChange('defaultStake', Number(e.target.value))}
              />
            </Col>
            <Col>
              <Button
                variant="outline-secondary decrement-button"
                onClick={() =>
                  handleFormDataChange('defaultStake', Number(formData.defaultStake - 1))
                }>
                -
              </Button>
              <Button
                variant="outline-secondary increment-button"
                onClick={() =>
                  handleFormDataChange('defaultStake', Number(formData.defaultStake + 1))
                }>
                +
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left fade-text-gray" style={{ fontSize: 10, marginTop: 0 }}>
                Min 1,00, Max 4,0000,00
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="float-left">{t('numpadpresetstakes')}</h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="g-2">
              <FormControl
                value={formData.presetStake1}
                className="preset"
                onChange={(e) => handleFormDataChange('presetStake1', Number(e.target.value))}
              />
            </Col>
            <Col className="g-2">
              <FormControl
                value={formData.presetStake2}
                className="preset"
                onChange={(e) => handleFormDataChange('presetStake2', Number(e.target.value))}
              />
            </Col>
            <Col className="g-2">
              <FormControl
                value={formData.presetStake3}
                className="preset"
                onChange={(e) => handleFormDataChange('presetStake3', Number(e.target.value))}
              />
            </Col>
            <Col className="g-2">
              <FormControl
                value={formData.presetStake4}
                className="preset g-3"
                onChange={(e) => handleFormDataChange('presetStake4', Number(e.target.value))}
              />
            </Col>
            <Col className="g-2">
              <FormControl
                value={formData.presetStake5}
                className="preset"
                onChange={(e) => handleFormDataChange('presetStake5', Number(e.target.value))}
              />
            </Col>
          </Row>
          <div className="">
            <FetchButton className="w-100" type="submit" variant="success" fetching={isSubmitting}>
              Save
            </FetchButton>
          </div>
        </Form>
      </Container>
    </section>
  );
}
