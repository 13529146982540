import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GoBack from '../general/go_back';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function Bank() {
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <Container>
      <Row>
        <Col xs={1} className="float-left mt15">
          <GoBack />
        </Col>
        <Col xs={2} className="float-left ">
          <div className="mt10"></div>
          <div style={{ color: '#000', fontSize: 20, fontWeight: '700' }}>{t('payout')}</div>
          <div style={{ color: 'black', fontSize: 14, marginLeft: 5 }}>{t('bank')}</div>
        </Col>
      </Row>
      <div className="mt5"></div>
      <hr className="m-0"></hr>

      <div className="mt10"></div>

      <div className="mt150"></div>
      <div style={{ justifyContent: 'center', alignItems: 'center' }}>
        <img
          style={{ height: '30%', width: '30%', marginTop: 20 }}
          src={`${AWS_BUCKET_URL}/Icons/115.png`}></img>
        <div className="mt10"></div>
        <div style={{ marginTop: 20, fontWeight: '700' }}>Payout Not Possible</div>

        <div className="mt15"></div>
        <Row>
          <div className="betWrapper">
            <div className="fsize-11">
              Please upload your{' '}
              <span className="green fweight-bold">
                <Link to="/uploaddocument">documents</Link>
              </span>{' '}
              and contact our{' '}
              <span className="green fweight-bold">
                <Link to="/contact" className="green-title">
                  support
                </Link>
              </span>
            </div>
          </div>
        </Row>
      </div>
    </Container>
  );
}
