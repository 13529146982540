import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Title_h6(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        {' '}
        <h6>{t(props.title)}</h6>
      </span>
      <span style={{ fontSize: 11, color: 'green' }}>
        {' '}
        <Link to={'/' + props.fRightRedirectPath}>{props.fRightText}</Link>
      </span>
    </div>
  );
}

export default Title_h6;
