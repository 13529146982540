import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { startLiveEvoCasinoSessionReq } from 'stores/auth/services';
import { getDeviceType, getLanguageCode } from 'toolkit/utils';

export default function LiveGame() {
  const location = useLocation();
  const [src, setSrc] = useState('');
  const user = useSelector((state) => state.auth.user);
  const device = getDeviceType(true);

  function StartSession() {
    const gameId = location.hash.split('-')[0];
    startLiveEvoCasinoSessionReq({
      gameId: gameId.slice(1),
      customerId: user.id,
      language: getLanguageCode().split('-')[0],
      exitUrl: 'example.com',
    })
      .then((r) => {
        setSrc(r.data);
      })
      .catch((e) => {
        console.log(e.message);
        //setSrc("https://play.gamesapi.cloud/?SessionId=1ec3a571d2fb3f7feeb0fu110724");
      });
  }

  useEffect(() => {
    if (user === null) {
      return;
    }
    StartSession();
  }, [user]);

  return (
    <div>
      {device === 'desktop' && (
        <iframe
          allow="autoplay"
          id="mainframe"
          src={src}
          className="slot-size"
          style={{ padding: 0, border: 0, position: 'fixed' }}
          donotallowfullscreen=""
          webkitallowfullscreen="0"
          mozallowfullscreen="0"
          allowFullScreen="0"></iframe>
      )}
      {device === 'mobile' && (
        <iframe
          allow="autoplay"
          id="mainframe"
          src={src}
          className="slot-size"
          style={{ padding: 0, border: 0, position: 'fixed' }}></iframe>
      )}
    </div>
  );
}
