import React from 'react';
import { AWS_BUCKET_URL } from '../../../toolkit/constants';

const NoItem = ({ message }) => {
  return (
    <div className="text-center">
      <img className="img-fluid mt-5" src={`${AWS_BUCKET_URL}/Icons/125.png`} alt={'bet won'} />
      <div className="gray-text mb-2">{message}</div>
    </div>
  );
};

export default NoItem;
