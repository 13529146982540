import { Button, Spinner } from 'react-bootstrap';

const FetchButton = (props) => {
  const { fetching, variant, type, className, onClick } = props;
  let innerEl;
  if (fetching) {
    innerEl = <Spinner animation="border" role="status" size="sm" />;
  } else {
    innerEl = props.children;
  }
  return (
    <Button
      className={className}
      variant={variant || 'primary'}
      type={type}
      disabled={fetching}
      onClick={onClick}>
      {innerEl}
    </Button>
  );
};

export default FetchButton;
