import { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MatchCard from 'components/general/match_card.js';
import { getSportTypeToActiveIcon, getSportTypeToWhiteIcon } from 'toolkit/utils';
import SportDetailItem from 'components/general/SportDetailItem';
import { fetchLiveMatches, setNextLiveMatches, setSelectedSport } from 'stores/match';
import eventsToGroup from 'toolkit/eventsToGroup.js';

function SportDetail() {
  const matchCardListRef = useRef(null);
  const dispatch = useDispatch();
  const selectedSport = useSelector((state) => state.match.selectedSport);
  const selectedMatch = useSelector((state) => state.match.selectedMatch);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const liveEvents = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]);
  }, shallowEqual);
  const { t } = useTranslation('translations', { useSuspense: false });
  const [lastScrollPosition, setLastScrollPosition] = useState(
    localStorage.getItem('scrollPosition') || 0
  );
  const [sports, setSports] = useState({});
  const [sport, setSport] = useState('');
  const [selectedMarketType, setSelectedMarketType] = useState(7);
  const location = useLocation();

  const handleLocationScroll = () => {
    const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
    setLastScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleLocationScroll);

    return () => {
      window.removeEventListener('scroll', handleLocationScroll);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('scrollPosition', lastScrollPosition.toString());
  }, [lastScrollPosition]);

  // useEffect(() => {
  //   const savedScrollPosition = localStorage.getItem('scrollPosition');
  //   if (savedScrollPosition && document.querySelector('.scrollable-y')) {
  //     setTimeout(() => {
  //       document.querySelector('.scrollable-y').scrollTop = Number(savedScrollPosition);
  //     }, 50);
  //   }
  // }, []);

  const fetchData = async () => {
    try {
      dispatch(fetchLiveMatches());
    } catch (error) {
      console.log("Can't fetch live matches", error);
    }
  };

  // useEffect(() => {
  //   useEffect(() => {
  //     const handleVisibilityChange = () => {
  //       if (!document.hidden) {
  //         fetchData();
  //       }
  //     };
  //     document.addEventListener('visibilitychange', handleVisibilityChange);
  //     fetchData();
  //     return () => {
  //       document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     };
  //   }, []);
  // }, []);

  useEffect(() => {
    if (selectedMatch) {
      const selectedMatchCard = document.querySelector(`a[href*="${selectedMatch}"]`);
      if (selectedMatchCard) {
        selectedMatchCard.scrollIntoView({ block: 'center' });
      }
    }
  }, []);

  useEffect(() => {
    // if (loading) return;
    const groups = eventsToGroup(liveEvents);
    setSports(groups);
    if (sport === '' && liveEvents.length > 0) {
      const type =
        selectedSport !== null || sports.hasOwnProperty(Number.parseInt(selectedSport)) === true
          ? selectedSport
          : Object.keys(groups)[0];

      setSport(type);
    }
  }, [sport, liveEvents]);

  useEffect(() => {
    if (sport !== '') {
      dispatch(setSelectedSport(sport));
    }
  }, [sport]);

  const selectSport = useCallback((id) => {
    setSport(id);
    dispatch(setNextLiveMatches());
  });

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  function calculateMenuItemsDynamic() {
    const menuItems = [];
    let icon = '';
    Object.keys(sports).map((sportType, index) => {
      if (sportType === sport) {
        icon = getSportTypeToActiveIcon(sport);
      } else {
        icon = getSportTypeToWhiteIcon(sportType);
      }
      if (sports[sportType].length > 0) {
        menuItems.push(
          <SportDetailItem
            key={index}
            active={sport === sportType}
            sportType={sportType}
            selectedSportType={sport}
            count={_.sum(sports[sportType].map((l) => l.matches.length))}
            icon={icon}
            onClick={() => {
              selectSport(sportType);
            }}
            text={t('sports.' + sportType)}
            className={'d-flex flex-column padding-header-icons'}
          />
        );
      }
    });

    return menuItems;
  }

  function getBetType() {
    const betTypes = [
      {
        label: 'RoM',
        id: 7
      },
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="tip-type" key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-12 ${
                selectedMarketType === betType.id ? 'selected' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getBets() {
    const matchCards = [];
    if (sports[sport] && sports[sport].length > 0) {
      sports[sport]
        .filter((l) => l.matches.length > 0)
        .forEach((liveMatch) => {
          matchCards.push(
            <Fragment key={liveMatch.matches[0].providerId}>
              <div className="league-names pt-0">
                {liveMatch.category + ' / ' + liveMatch.league}
              </div>
              {liveMatch.matches.map((match, index) => (
                <MatchCard
                  key={match.providerId}
                  providerId={match.providerId}
                  marketType={selectedMarketType}
                  index={index}
                  sport={sport}
                  path={location.pathname}
                  locales={locales}
                  selectedLocale={selectedLocale}
                />
              ))}
            </Fragment>
          );
        });
    }

    return matchCards;
  }

  function getBetTypeTitle() {
    if (selectedMarketType === 29) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            Yes
          </Col>
          <Col xs={4} className="fs-16" style={{ display: 'flex' }}>
            No
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 8) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 14) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 18) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            +
          </Col>
          <Col xs={4} className="fs-16">
            -
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 1 || selectedMarketType === 7) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col xs={4} className="fs-16">
          1X
        </Col>
        <Col xs={4} className="fs-16">
          12
        </Col>
        <Col xs={4} className="fs-16">
          X2
        </Col>
      </Row>
    );
  }

  function checkSportTypes(sport) {
    if (
      sport === '1' ||
      sport === '2' ||
      sport === '4' ||
      sport === '3' ||
      sport === '6' ||
      sport === '12' ||
      sport === '13' ||
      sport === '5' ||
      sport === '11' ||
      sport === '14'
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className="g-0 container-fluid scrollable-y">
      <div className="scrollmenuFullWidth blackBgColor">{calculateMenuItemsDynamic()}</div>
      {sport === '0' && (
        <>
          <>
            <Row>
              <Col className="tip-types-container">{getBetType()}</Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>{getBetTypeTitle(selectedMarketType)}</Col>
            </Row>
          </>
        </>
      )}

      {sport !== '0' && checkSportTypes(sport) && (
        <>
          {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
          <Row>
            <Col></Col>
            <Col>
              <Row>
                <Col xs={2} style={{ fontSize: 16 }}></Col>
                <Col xs={5} style={{ fontSize: 16, textAlign: 'end' }}>
                  1
                </Col>
                <Col xs={5} style={{ fontSize: 16 }}>
                  2
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {sport !== '0' && !checkSportTypes(sport) && (
        <>
          {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
          <Row>
            <Col></Col>
            <Col>
              <Row>
                <Col xs={4} style={{ fontSize: 16, textAlign: 'end' }}>
                  1
                </Col>
                <Col xs={4} style={{ fontSize: 16 }}>
                  0
                </Col>
                <Col xs={4} style={{ fontSize: 16, textAlign: 'justify' }}>
                  2
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <div className="MatchCardListWrapper">
        <div className="MatchCardListWrapper" ref={matchCardListRef}>
          {getBets()}
        </div>
      </div>
    </div>
  );
}

export default SportDetail;
