import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import BetTitleAndBox from './bet_title_and_box.js';
import {
  gameDateToMatchDay,
  gameDateToMatchTime,
  getMatchTime,
  checkHasMarketVariable,
  sportTypeToSportId
} from 'toolkit/utils.js';
import * as _ from 'lodash';
import { getTeamNameHelper } from '../../helper/matchDetailHelper';
import { EnumEventStatus, EnumMatchStatusName } from 'toolkit/Enums';

function MatchCard(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  const { providerId } = props;
  if (!providerId) return null;
  const match = useSelector((state) => state.match.events[providerId]);
  if (!match || match.isMatch === false) return null;

  const defaultMarketObject = {
    1: ['1', 'X', '2'],
    7: ['1', 'X', '2'],
    8: ['1', 'X', '2'],
    10: ['1X', '12', 'X2'],
    14: ['1', 'X', '2'],
    18: ['+', '-'],
    29: ['Yes', 'No'],
    113: ['1', 'X', '2'],
    114: ['1', 'X', '2'],
    186: ['1', '2']
  };

  const sortByMarketId = (arr) => {
    return _.orderBy(arr, (market) => {
      if (market === null || market === undefined) return false;
      const marketVariable = checkHasMarketVariable(market.id, market.stringId);
      if (marketVariable) {
        if (!isNaN(Number(marketVariable))) return Number(marketVariable);
        return marketVariable;
      }
      return false;
    });
  };

  const getDefaultMarket = () => {
    // hide bettitle if match card doesn't have the selected market
    if (props.hideIfNoMarket) {
      return null;
    }
    let defaultMarket;
    const winnerBet = match.markets.find((m) => m.id === 1);
    if (winnerBet) {
      defaultMarket = winnerBet;
    } else {
      const otherWinningIds = [7, 113, 114, 186, 219, 340, 251];
      defaultMarket = match.markets.find((m) => otherWinningIds.includes(m.id));
    }
    if (!defaultMarket) {
      defaultMarket = {
        id: 186,
        name: '',
        outcomeType: '',
        stringId: '',
        outcomes: defaultMarketObject[186],
        renderNull: true
      };
    }
    return defaultMarket;
  };

  function MatchCardGetTitle() {
    const sportType = match.sport;
    const providerId = match.providerId;
    let marketType = props.marketType;
    let markets = [];
    if (!match.markets || match.markets.length === 0) {
      return (
        <div className="d-flex justify-content-center align-item-center">
          {t('components.matchcard.noMarket')}
        </div>
      );
    } else if (sportType === 0) {
      if (
        match.matchStatusId == EnumMatchStatusName.FIRST_EXTRA ||
        match.matchStatusId == EnumMatchStatusName.SECOND_EXTRA
      ) {
        if (marketType === 7 && match.markets.find((x) => x.id === 114)) {
          marketType = 114;
        } else if (marketType === 1 && match.markets.find((x) => x.id === 113)) {
          marketType = 113;
        } else if (marketType === 8 && match.markets.find((x) => x.id === 115)) {
          marketType = 115;
        }
      }

      const filteredMarkets = match.markets.filter((x) => x.id === marketType);
      if (filteredMarkets.length === 0) {
        if (props.lockIfNoMarket) {
          markets.push({
            id: marketType,
            name: '',
            outcomeType: '',
            stringId: '',
            outcomes: defaultMarketObject[marketType],
            renderNull: true
          });
        } else {
          if (marketType === 7 && match.sport === 0 && match.matchStatusId === 0) {
            markets.push(getDefaultMarket());
          } else {
            markets.push({
              id: marketType,
              name: '',
              outcomeType: '',
              stringId: '',
              outcomes: defaultMarketObject[marketType],
              renderNull: true
            });
          }
        }
      } else {
        markets.push(...filteredMarkets);
      }
    } else {
      markets.push(getDefaultMarket());
    }

    markets = markets.sort(
      (t1, t2) => Number(t1.stringId.split('-S:')[1]) - Number(t2.stringId.split('-S:')[1])
    );

    if (marketType === 14) {
      markets = sortByMarketId(markets);
    }
    return markets.map((market, index) => (
      <BetTitleAndBox
        key={index}
        market={market}
        matchId={providerId}
        sportType={sportType}
        lockIfNoMarket={props.lockIfNoMarket}
        isLastMarket={index === markets.length - 1}
      />
    ));
  }

  // if (!getDefaultMarket()) {
  //   return <div></div>;
  // }

  const servingBall =
    match.sport == sportTypeToSportId('Volleyball') ? (
      <img className="serving-ball" src={`${AWS_BUCKET_URL}/Icons/182.png`} />
    ) : (
      <img className="serving-ball" src={`${AWS_BUCKET_URL}/Icons/118.png`} />
    );
  const homeScores = [];
  const awayScores = [];
  const periodScoresLenght = match.periodScores.length;
  match.periodScores.forEach((element, index) => {
    if (index === periodScoresLenght - 1) {
      homeScores.push(
        <span className="text-success" key={'homeScore1'}>
          {element.homeScore}
        </span>
      );
      awayScores.push(
        <span className="text-success" key={'awayScore1'}>
          {element.awayScore}
        </span>
      );
    } else {
      homeScores.push(<span key={'homeScore2'}>{element.homeScore} </span>);
      awayScores.push(<span key={'awayScore2'}>{element.awayScore} </span>);
    }
  });

  const homeRedCardCount = (
    <span className="badge bg-danger ms-2 px-1">{match.homeRedCardCount}</span>
  );
  const awayRedCardCount = (
    <span className="badge bg-danger ms-2 px-1">{match.awayRedCardCount}</span>
  );
  const getTeam = (team) => {
    const lastPeriodScore = match.periodScores[match.periodScores.length - 1];
    const homeServingBall =
      lastPeriodScore && lastPeriodScore.homeScore > lastPeriodScore.awayScore;
    const awayShowServingBall =
      lastPeriodScore && lastPeriodScore.homeScore <= lastPeriodScore.awayScore;
    const redCardCountSource = team == 'team1' ? 'homeRedCardCount' : 'awayRedCardCount';

    const teamName = getTeamNameHelper(
      props.locales,
      props.selectedLocale,
      match.competitors[team == 'team1' ? 0 : 1],
      t
    );
    const showServingBall =
      team == 'team1' ? homeServingBall && servingBall : awayShowServingBall && servingBall;
    return (
      <span>
        {teamName} {showServingBall}
        {match[redCardCountSource] > 0
          ? team == 'team1'
            ? homeRedCardCount
            : awayRedCardCount
          : null}
      </span>
    );
  };

  const getScore = (team) => {
    if (match.eventStatus == EnumEventStatus.RUNNING) {
      if (
        match.sport == sportTypeToSportId('Volleyball') ||
        match.sport == sportTypeToSportId('Tennis') ||
        match.sport == sportTypeToSportId('TableTennis')
      ) {
        return <span className="float-end">{team == 'team1' ? homeScores : awayScores}</span>;
      } else {
        return (
          <span className="float-end">{team == 'team1' ? match.homeScore : match.awayScore}</span>
        );
      }
    } else {
      return null;
    }
  };

  const getTeamSolo = (team) => {
    const redCardCountSource = team == 'team1' ? 'homeRedCardCount' : 'awayRedCardCount';
    const teamName = getTeamNameHelper(
      props.locales,
      props.selectedLocale,
      match.competitors[team == 'team1' ? 0 : 1],
      t
    );
    return (
      <>
        {match.sport == 13 || match.sport == 2 || match.sport == 12 ? (
          getTeam(team)
        ) : (
          <span>
            {teamName}
            {match[redCardCountSource] > 0
              ? team == 'team1'
                ? homeRedCardCount
                : awayRedCardCount
              : null}
          </span>
        )}
      </>
    );
  };

  const getMatchCardBg = (match) => {
    if (match.eventStatus > 0) {
      if (match.matchStatusId === 31) {
        return '-bg-ht';
      } else {
        return '';
      }
    } else {
      if (match.bookingStatus === 2) {
        return '-bg-will-live';
      } else {
        return '-bg-will-no-live';
      }
    }
  };

  return (
    <Link
      to={{
        pathname: '/match-detail',
        hash: `#${match.providerId}`
      }}>
      <div className="matchCard" style={{ flexWrap: 'nowrap' }}>
        <div className="row" xs={8}>
          <Col xs={7} className="dayTime mb-1">
            {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
              <span className={'day2' + getMatchCardBg(match)}>
                {t('components.matchcard.live')}
              </span>
            ) : (
              <span className={'day' + getMatchCardBg(match)}>
                {gameDateToMatchDay(match.gameDate)}
              </span>
            )}
            {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
              match.sport === 0 ? (
                getMatchTime(match, t)
              ) : (
                <span className="time">
                  {t(`components.matchcard.time.byMatchStatusId.${match.matchStatusId}`)}
                </span>
              )
            ) : (
              <span className="time">{gameDateToMatchTime(match.gameDate)}</span>
            )}
          </Col>
        </div>
        <Row className="teams_wrapper">
          <Col xs={props.marketType === 14 ? 5 : 6} className="pr-0" style={{ display: 'grid' }}>
            {getTeamSolo('team1')}
            {getTeamSolo('team2')}
          </Col>
          <Col
            xs={1}
            className={`score-card pl-0 ${
              match.sport === 2 || (match.sport === 13 && 'tenis-fs')
            }`}>
            {getScore('team1')}
            {getScore('team2')}
          </Col>
          <Col xs={6} className="pl-0 gutter-0-5" style={{ position: 'relative', paddingRight: 2 }}>
            {MatchCardGetTitle()}
          </Col>
        </Row>
      </div>
    </Link>
  );
}
export default MatchCard;
