import { configureStore } from '@reduxjs/toolkit';
import matchReducer from './match';
import authReducer from './auth';
import betSlipReducer from './betSlip';
import appReducer from './app';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 }
    }),
  reducer: {
    app: appReducer,
    match: matchReducer,
    auth: authReducer,
    betSlip: betSlipReducer
  }
});
