import React from 'react';
import { Pagination, Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Paginate = (props) => {
  const { t } = useTranslation('translations', { useSuspense: false });

  const { data, meta } = props.response;
  if (!meta || !data) return null;
  const active = meta.currentPage;
  const items = [];

  function getPaginationInfo() {
    let paginationInfo = '';
    let pageNumber = meta.currentPage === 0 ? 1 : meta.currentPage + 1;
    paginationInfo = (
      <p className="pagination-info">
        {pageNumber === 1 ? 1 : (pageNumber - 1) * meta.pageSize} -
        {meta.totalPages === pageNumber ? meta.totalCount : pageNumber * meta.pageSize}
        {''} of {meta.totalCount}
      </p>
    );
    return paginationInfo;
  }

  function getPaginationArray() {
    const paginationArray = (
      <Pagination size="lg" bsPrefix="pagination" style={{ marginTop: 0 }}>
        <Row>
          <Col xs={4} style={{ display: 'inline-flex' }}>
            <Pagination.First
              onClick={() => props.onPaginate(0)}
              disabled={meta.currentPage === 0}
            />
            <Pagination.Prev
              onClick={() => props.onPaginate(meta.currentPage - 1)}
              disabled={meta.currentPage === 0}
            />
            {/* {getPaginationNumber()} */}
          </Col>
          <Col xs={4} style={{ display: 'inline-flex' }}>
            {getPaginationInfo()}
          </Col>
          <Col xs={4} style={{ display: 'inline-flex' }}>
            <Pagination.Next
              onClick={() => props.onPaginate(meta.currentPage + 1)}
              disabled={meta.currentPage === meta.totalPages - 1}
            />
            <Pagination.Last
              onClick={() => props.onPaginate(meta.totalPages - 1)}
              disabled={meta.currentPage === meta.totalPages - 1}
            />
          </Col>
        </Row>
      </Pagination>
    );
    return paginationArray;
  }

  // for (let number = 0; number < meta.totalPages; number++) {
  //   items.push(
  //     <Pagination.Item
  //       key={number}
  //       active={number === active}
  //       onClick={() => {
  //         if (number === active) return;
  //         props.onPaginate(number);
  //       }}>
  //       {number + 1}
  //     </Pagination.Item>
  //   );
  // }
  return <div className="d-flex justify-content-center">{meta && getPaginationArray()}</div>;
};

export default Paginate;
