import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GoBack from '../general/go_back';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function UploadDocument() {
  const { t } = useTranslation('translations');
  const [detailsShown, setDetailsShown] = useState(false);

  return (
    <Container>
      <Row>
        <Col xs={1} className="mt5">
          <GoBack />
        </Col>
        <Col>
          <div className="float-left mt-3">{t('myprofile')}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="float-left mt-3">{t('uploaddocuments')}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="float-left fade-text-gray mt-2 font-size-12 text-align-left">
            To verify your identify please upload a copy of your identifaction document.
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="float-left mt-2 font-size-12">{t('senddocumentsnorobetsupport')}</h6>
        </Col>
      </Row>
      <Row className="upload-document-border" style={{ marginRight: 2, marginLeft: 2 }}>
        <Col xs={12}>
          <h6 className="float-left font-size-12">{t('personalidcard')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-left fade-text-gray font-size-12 text-align-left">
            To verify your identity please upload a copy of your identification document. Adoptable
            documents are: Driving License, National ID Card.
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-left">
            <Form.Label htmlFor="upload-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input type="file" name="photo" id="upload-photo" className="visible-file-input" />
          </div>
          <div className="float-left fade-text-gray ml-5">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-right mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row
        className="upload-document-border"
        style={{ marginRight: 2, marginLeft: 2, marginTop: 10 }}>
        <Col xs={12}>
          <h6 className="float-left font-size-12">{t('addressinformation')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-left fade-text-gray font-size-12 text-align-left">
            For confirming your address please upload a copy of your latest Utility Bill, showing
            your name and address. Acceptable documents are Water,Electrically, Telephone
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-left">
            <Form.Label htmlFor="upload-address-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input
              type="file"
              name="photo"
              id="upload-address-photo"
              className="visible-file-input"
            />
          </div>
          <div className="float-left fade-text-gray ml-5">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-right mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row
        className="upload-document-border"
        style={{ marginRight: 2, marginLeft: 2, marginTop: 10 }}>
        <Col xs={12}>
          <h6 className="float-left font-size-12">{t('bankaccountstatement')}</h6>
        </Col>
        <Col xs={12}>
          <h6 className="float-left fade-text-gray font-size-12 text-align-left">
            To verify your account please upload your account statement here. Your bank statemment
            must show the following: IBAN and BIC, logo and name of the bank and your full name
          </h6>
        </Col>
        <Col xs={6}>
          <div className="float-left">
            <Form.Label htmlFor="upload-bank-account-photo">
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/71.png`} />
            </Form.Label>
            <input
              type="file"
              name="photo"
              id="upload-bank-account-photo"
              className="visible-file-input"
            />
          </div>
          <div className="float-left fade-text-gray ml-5">PNG OR JPG</div>
        </Col>
        <Col xs={6}>
          <Button variant="secondary" className="float-right mb-2" size="sm">
            {t('upload')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h6 className="float-left mt-2">{t('otherdetails')}</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={9} sm={9}>
          <h6 className="float-left mt-2">{t('documenthistory')}</h6>
        </Col>
        <Col xs={3} className="item3">
          <span className="float-right light-grey-text">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col xs={9} sm={9}>
          <h6 onClick={() => setDetailsShown(!detailsShown)} className="float-left">
            {t('kycdetails')}
          </h6>
        </Col>
        <Col xs={3} sm={3} className="item3">
          <span className="float-right light-grey-text">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      {detailsShown && (
        <Row>
          <Col sm={12}>
            <div className="float-left fade-text-gray">{t('depositlimit')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-left fade-text-gray">{t('withdrawallimit')}</div>
            <div className="float-right">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-left fade-text-gray">{t('allowedtoplaywithcash')}</div>
            <div className="float-right">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-left fade-text-gray">{t('allowedtodeposit')}</div>
            <div className="float-right">{t('false')}</div>
          </Col>
          <Col sm={12}>
            <div className="float-left fade-text-gray">{t('allowedtowithdrawal')}</div>
            <div className="float-right">{t('false')}</div>
          </Col>
        </Row>
      )}
      <hr></hr>
    </Container>
  );
}
