import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Container } from 'react-bootstrap';
import {
  fetchEventsByCategory,
  fetchEventsByCategoryWithHours,
  fetchEventsBySport
} from 'stores/match';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import {
  calculateTimeParameter,
  isToday,
  sportTypeToSportId,
  sportTypetToSportIdString
} from 'toolkit/utils.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import MatchCard from 'components/general/match_card.js';
import SportDetailItem from 'components/sport/SportDetailItem.js';
import Loading from 'components/general/loading';

export default function SportDetailsMatches() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const { sportType } = useParams();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('category');
  const leagueId = searchParams.get('league');
  const time = searchParams.get('time');
  const [loading, setLoading] = useState(false);
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [selectedMarketType, setSelectedMarketType] = useState(1);

  const getData = async () => {
    try {
      setLoading(true);
      if (categoryId === 'all') {
        const { betEvents } = await dispatch(
          fetchEventsBySport({
            sport: sportTypeToSportId(sportType),
            dateFilter: calculateTimeParameter(time)
          })
        ).unwrap();
        setFetchedEvents(
          Object.values(betEvents)
            .map((c) => Object.values(c))
            .flat()
            .flat()
        );
      } else if (time) {
        const { betEvents } = await dispatch(
          fetchEventsByCategory({ category: categoryId, dateFilter: calculateTimeParameter(time) })
        ).unwrap();
        setFetchedEvents(Object.values(betEvents).flat());
      } else {
        const { betEvents } = await dispatch(
          fetchEventsByCategoryWithHours({ Category: categoryId, Hours: 0 })
        ).unwrap();
        setFetchedEvents(Object.values(betEvents).flat());
      }
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    if (!loading && fetchedEvents.length > 0) {
      let tempLeagueArr = [];
      if (fetchedEvents.find((e) => isToday(new Date(e.gameDate)))) {
        tempLeagueArr.push({
          leagueId: 'Today',
          leagueName: t(`today`),
          events: []
        });
      }
      fetchedEvents.forEach((event) => {
        if (isToday(new Date(event.gameDate))) {
          const todayIndex = tempLeagueArr.findIndex((l) => l.leagueId === 'Today');
          tempLeagueArr[todayIndex].events.push(event);
        }
        const { leagueId } = event;
        const leagueIndex = tempLeagueArr.findIndex((l) => l.leagueId === leagueId);
        if (leagueIndex === -1) {
          tempLeagueArr.push({
            leagueId,
            leagueName: event.league,
            events: [event]
          });
        } else {
          tempLeagueArr[leagueIndex].events.push(event);
        }
      }, []);
      tempLeagueArr = tempLeagueArr.sort((a, b) => {
        if (a.leagueId === 'Today' || b.leagueId === 'Today') return 1;
        return Number(b.leagueId.split(':')[2]) < Number(a.leagueId.split(':')[2]) ? 1 : -1;
      });
      setLeagues(tempLeagueArr);
      if (leagueId && tempLeagueArr.find((l) => l.leagueId === leagueId)) {
        setSelectedLeague(leagueId);
      } else if (tempLeagueArr.find((l) => l.leagueId === 'Today')) {
        setSelectedLeague('Today');
      } else {
        setSelectedLeague(tempLeagueArr[0].leagueId);
      }
    }
  }, [fetchedEvents]);

  function getLeagues() {
    if (leagues.length > 0) {
      return leagues.map(({ leagueId, events, leagueName }) => (
        <SportDetailItem
          key={leagueId}
          active={selectedLeague === leagueId}
          onClick={() => setSelectedLeague(leagueId)}
          text={leagueName}
          count={leagueId === 'Today' ? undefined : events.length}
          icon={
            <img
              className="icon-color"
              width="25"
              height="25"
              src={`${AWS_BUCKET_URL}/Icons/star${
                selectedLeague === leagueId ? 'fill' : 'empty'
              }.svg`}
            />
          }
        />
      ));
    }
    return null;
  }

  function getBetType() {
    const betTypes = [
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      },
      {
        label: 'DC',
        id: 10
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="tip-type" key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-12 ${
                selectedMarketType === betType.id ? 'selected' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getBets() {
    const matchCardList = [];
    const leagueArray = [];
    const selectedLeagueObj = leagues.find((l) => l.leagueId === selectedLeague);
    if (!selectedLeagueObj) return;
    const { events } = selectedLeagueObj;
    if (events.length > 0) {
      events
        .sort((a, b) => new Date(a.gameDate).getTime() - new Date(b.gameDate).getTime())
        .filter((a) => a.markets && a.league && a.gameNumber)
        .forEach(function (bet) {
          const findedMarket = bet.markets.find((market) => market.id === selectedMarketType);
          const lock =
            !findedMarket ||
            !findedMarket.outcomes ||
            findedMarket.outcomes.findIndex((element) => element.odds !== null) === -1;
          if (!leagueArray.includes(bet.league)) {
            leagueArray.push(bet.league);
            matchCardList.push(
              <div
                className="league-names text-start pb-1 fw-bold pt-1"
                key={'league-' + bet.league}>
                {bet.league}
              </div>
            );
          }
          return matchCardList.push(
            <MatchCard
              key={bet.gameNumber}
              marketType={selectedMarketType}
              providerId={bet.providerId}
              lockIfNoMarket={lock}
            />
          );
        });
    }
    return matchCardList;
  }

  function titles() {
    const titleList = [];
    titleList.push(
      <div className="float-start w-100 mb-1" key={sportType}>
        <span>Sport | {sportType}</span>
      </div>
    );
    return titleList;
  }

  function categoryName() {
    if (categoryId === 'all') return t('allcategories');
    const selectedLeagueObj = leagues.find((l) => l.leagueId === selectedLeague);
    if (!selectedLeagueObj) return '';
    return selectedLeagueObj.events[0].category;
  }

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  if (loading) return <Loading message={t('search.loadingMatches')} />;

  function getBetTypeTitle() {
    if (selectedMarketType === 29) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            Yes
          </Col>
          <Col xs={4} className="fs-16" style={{ display: 'flex' }}>
            No
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 8) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 14) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 18) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            +
          </Col>
          <Col xs={4} className="fs-16">
            -
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 1 || selectedMarketType === 7) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col xs={4} className="fs-16">
          1X
        </Col>
        <Col xs={4} className="fs-16">
          12
        </Col>
        <Col xs={4} className="fs-16">
          X2
        </Col>
      </Row>
    );
  }

  function checkSportTypes(sport) {
    if (
      sport === '1' ||
      sport === '2' ||
      sport === '4' ||
      sport === '3' ||
      sport === '6' ||
      sport === '12' ||
      sport === '13' ||
      sport === '5' ||
      sport === '11' ||
      sport === '14'
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Container fluid className="g-0 scrollable-y">
        <div className="overflow-auto d-flex gap-2">{getLeagues()}</div>
        <TitleHistoryBack title={titles()} subTitle={categoryName()} />
        {sportType === 'Football' && (
          <>
            <Row className="mt-n2">
              <Col className="tip-types-container">{getBetType()}</Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>{getBetTypeTitle(selectedMarketType)}</Col>
            </Row>
          </>
        )}

        {sportType !== 'Football' && checkSportTypes(sportTypetToSportIdString(sportType)) && (
          <>
            {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
            <Row>
              <Col></Col>
              <Col>
                <Row>
                  <Col xs={2} style={{ fontSize: 16 }}></Col>
                  <Col xs={5} style={{ fontSize: 16, textAlign: 'end' }}>
                    1
                  </Col>
                  <Col xs={5} style={{ fontSize: 16 }}>
                    2
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {sportType !== 'Football' && !checkSportTypes(sportTypetToSportIdString(sportType)) && (
          <>
            {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
            <Row>
              <Col></Col>
              <Col>
                <Row>
                  <Col xs={4} style={{ fontSize: 16, textAlign: 'end' }}>
                    1
                  </Col>
                  <Col xs={4} style={{ fontSize: 16 }}>
                    0
                  </Col>
                  <Col xs={4} style={{ fontSize: 16, textAlign: 'justify' }}>
                    2
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <div className="">{getBets()}</div>
      </Container>
    </>
  );
}
