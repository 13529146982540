import { AWS_BUCKET_URL } from '../../toolkit/constants';
// import SubTitleH6 from '../../components/general/subTitle_h6.js';
// import TitleH6 from '../../components/general/title_h6.js';
import { useNavigate } from 'react-router-dom';

export default function GoBack(props) {
  const navigate = useNavigate();
  const goBack = () => {
    if (props.location != null) {
      if (props.selectSport != null) {
        navigate(props.location, { state: { selectSport: props.selectSport } });
      } else {
        navigate(props.location);
      }
    } else {
      navigate(-1);
    }
  };

  // const titles = (
  //   <div>
  //     <div className="sport_time_detail sport-title">
  //       <SubTitleH6 title="Sport" />
  //     </div>
  //     <div className="sport_time_detail title2">
  //       <TitleH6 title={props.title} />
  //     </div>
  //   </div>
  // );

  return (
    <div className="me-2" style={{ fontSize: 18 }} onClick={goBack}>
      {props?.position == 'detail' ? (
        <img
          className="icon-color"
          width="18"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`}
        />
      ) : (
        <img width="18" height="25" src={`${AWS_BUCKET_URL}/Icons/backicon-caret.svg`} />
      )}
    </div>
  );
}
