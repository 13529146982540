import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column my-5">
      <Spinner animation="border" role="status" variant={props.variant || 'primary'}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <span className="mt-3">{props.message}</span>
    </div>
  );
};

export default Loading;
