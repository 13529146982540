import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { fetchResultsReq } from 'stores/match/services.js';
import ResultCard from 'components/general/result_card.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import { gameDay, gameTime } from 'toolkit/utils';
import { sportTypeToSportId } from 'toolkit/utils';
import { useTranslation } from 'react-i18next';

export default function ResultDetail() {
  const { sportType, itemName } = useParams();
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const [resultItems, setResultItems] = useState([]);
  const { t } = useTranslation('translations', { useSuspense: false });

  const getData = async () => {
    try {
      const response = await fetchResultsReq({
        sportId: sportTypeToSportId(sportType),
        categoryId: itemName,
        searchText: 'string'
      });
      setResultItems(response);
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  let categoryText = '';
  if (categoryNames) {
    categoryText = categoryNames[itemName];
  }

  function getPeriodScoresHome(item) {
    if (item.periodScores.length > 0) {
      const homeScore = item.periodScores?.map((n) => n.homeScore).join('  ');
      if (sportTypeToSportId(sportType) == 2 || sportTypeToSportId(sportType) == 13) {
        const result = (homeScore ? homeScore + ' | ' : '') + item.homeScore;
        return result;
      } else if (sportTypeToSportId(sportType) == 0) {
        const result =
          (item.periodScores[0]?.homeScore ? item.periodScores[0]?.homeScore + ' | ' : '') +
          item.homeScore;
        return result;
      } else {
        return item.homeScore;
      }
    } else {
      return item.homeScore;
    }
  }

  function getPeriodScoresAway(item) {
    if (item.periodScores.length > 0) {
      const awayScore = item.periodScores?.map((n) => n.awayScore).join('  ');
      if (sportTypeToSportId(sportType) == 2 || sportTypeToSportId(sportType) == 13) {
        const result = (awayScore ? awayScore + ' | ' : '') + ' | ' + item.awayScore;
        return result;
      } else if (sportTypeToSportId(sportType) == 0) {
        const result =
          (item.periodScores[0]?.awayScore ? item.periodScores[0]?.awayScore + ' | ' : '') +
          item.awayScore;
        return result;
      } else {
        return item.awayScore;
      }
    } else {
      return item.awayScore;
    }
  }

  if (categoryText.includes(' ')) {
    categoryText = categoryText.replaceAll(' ', '');
  }

  return (
    <Container fluid className="text-start me-3 mt-2">
      <TitleHistoryBack
        title={t(`start`) + ' |' + t(`results`)}
        subTitle={t(`categories.${categoryText}`)}
      />
      <span className="text-left">&nbsp; </span>

      <div className=" mt-n4">
        {resultItems
          .sort((a, b) => new Date(b.gameDate).getTime() - new Date(a.gameDate).getTime())
          .map((item, index) =>
            item.periodScores ? (
              <ResultCard
                key={index}
                day={gameDay(item.gameDate)}
                time={gameTime(item.gameDate)}
                count="10"
                team1={item.competitors[0]?.name}
                team2={item.competitors[1]?.name}
                scoreteam1={getPeriodScoresHome(item)}
                scoreteam2={getPeriodScoresAway(item)}
                sportType={sportTypeToSportId(sportType)}></ResultCard>
            ) : (
              ''
            )
          )}
      </div>
    </Container>
  );
}
