import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TitleHistoryBack from 'components/general/TitleHistoryBack';
import Loading from 'components/general/loading.js';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import { getCustomerTransactionsReq } from 'stores/auth/services';
import Paginate from 'components/general/pagination.js';
import { intlFormat } from 'date-fns';

const intlFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  formatMatcher: 'basic',
  localeMatcher: 'lookup'
};
const intlFormatLocaleOptions = {
  locale: 'en-EN'
};
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.guess();

export default function Transactions() {
  const balance = useSelector((state) => state.auth.balance);
  const { t } = useTranslation('translations', { useSuspense: false });
  const [transactions, setTransactions] = useState({ data: [], meta: null });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const routeParams = useParams();
  const { customerId } = routeParams;

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getCustomerTransactionsReq({
        selectedCustomer: [customerId],
        ...getTransactionsParams()
      });
      setTransactions({
        data: response.data,
        meta: response.metaData
      });
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, [customerId, pageNumber]);

  function getTransactionsParams() {
    const startDate = new Date();
    const endDate = new Date();
    const beforeMonth = startDate.setMonth(startDate.getMonth() - 1);
    return {
      pageNumber: pageNumber,
      pageSize: 25,
      searchBetSlipId: '',
      startDate: intlFormat(new Date(beforeMonth), intlFormatOptions, intlFormatLocaleOptions),
      endDate: intlFormat(new Date(endDate), intlFormatOptions, intlFormatLocaleOptions),
      selectedTransactionType: '0'
    };
  }

  const onPaginate = (pageIndex) => {
    setPageNumber(pageIndex);
  };

  function getSubstract(type) {
    switch (type) {
      case 2:
      case 3:
        return '-';
      default:
        return '';
    }
  }

  function getTransactionType(type) {
    if (type <= 6 || (type >= 9 && type <= 14) || (type <= 21 && type <= 22)) {
      return t(`transactionType.${type}`);
    } else {
      return t(`transactionType.${0}`);
    }
  }

  function getTransactionTypeColor(id) {
    switch (id) {
      case 1:
        return 'transaction-deposit';
      case 2:
        return 'text-danger';
      case 3:
        return 'text-danger';
      case 4:
        return 'text-success';
      case 5:
        return 'text-primary';

      //casino related things
      case 9:
      case 12:
        return 'text-success';
      case 10:
        return 'text-danger';
      case 11:
        return 'text-danger';
      case 13:
        return 'Negative Correlation';
      case 14:
        return 'Positive Correlation';
      default:
        return '';
    }
  }

  function getTransactionTypeIdString(type) {
    switch (type) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 13:
      case 14:
      case 21:
      case 22:
        return t('betslipid');

      case 9:
      case 10:
        return t('roundId');

      case 11:
      case 12:
        return t('gameId');

      default:
        return `Undefined ID(${type})`;
    }
  }

  function getTransactionIdFromType(transaction) {
    switch (transaction?.transactionType) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 13:
      case 14:
      case 21:
      case 22:
        if (transaction.hasOwnProperty('betSlipId')) {
          if (transaction.betSlipId === null) {
            return 'Empty ID';
          }
          return transaction?.betSlipId;
        }
        //We don't expect it to fall here if id's are in range 1-6 otherwise something is wrong in server side
        return 'ID Error';

      case 9:
      case 10:
      case 11:
      case 12:
        if (transaction.hasOwnProperty('gameId')) {
          if (transaction.gameId == null) {
            return 'Empty ID';
          }
          return transaction?.gameId;
        }
        //We don't expect it to fall here if id's are in range 11-13 otherwise something is wrong in server side
        return 'ID Error';

      default:
        return `Undefined ID(${transaction?.transactionType})`;
    }
  }

  return (
    <>
      <Container>
        {loading ? (
          <Loading message={t('loading')} />
        ) : (
          <>
            <div className="mt-2"></div>
            <TitleHistoryBack location="/profile" title={t('myfinance')} subTitle={t('bank')} />
            <Row>
              <Col xs={2} className="float-start text-muted fw-bold fs-9 mt-2">
                {t('balance')}
              </Col>
              <Col xs={10} className="float-end">
                <hr className="text-end me-0"></hr>
              </Col>
            </Row>
            <div className="mt-2"></div>
            <Row>
              <Col xs={4} className="float-start fs-8 ps-5">
                {t('accountbalance')}:
              </Col>
              <Col xs={8} className="float-end">
                <div className="float-end fs-6">
                  {balance.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + ' €'}
                </div>
              </Col>
            </Row>
            <div className="mt-3"></div>
            <hr className="m-0"></hr>
            {transactions.data.map((transaction) => {
              return (
                <div key={transaction.id} className="fs-7">
                  <hr className="m-0"></hr>
                  <Row className="mt-2 mb-2">
                    <Col xs={4}>
                      <span className="float-start">{t('date')}</span>
                    </Col>
                    <Col xs={8}>
                      <span className="float-end">
                        {dayjs
                          .tz(transaction.dateCreated, 'UTC')
                          .local()
                          .format('DD/MM/YYYY HH:mm')}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={6}>
                      <span className="float-start">
                        {getTransactionTypeIdString(transaction.transactionType)}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">{getTransactionIdFromType(transaction)}</span>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={6}>
                      <span className="float-start">
                        {t('balancebefore') +
                          (transaction.accountType === 5100 ? ` (${t('bonus')})` : '')}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">{transaction.balanceBefore.toFixed(2)}€</span>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={6}>
                      <span className="float-start">
                        {t('amount') + (transaction.accountType === 5100 ? ` (${t('bonus')})` : '')}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">
                        {getSubstract(transaction.transactionType)}
                        {transaction.amount}€
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={6}>
                      <span className="float-start">
                        {t('balanceafter') +
                          (transaction.accountType === 5100 ? ` (${t('bonus')})` : '')}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">{transaction.balanceAfter.toFixed(2)}€</span>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col xs={6}>
                      <span className="float-start">{t('transactiontype')}</span>
                    </Col>
                    <Col xs={6}>
                      <span
                        className={`float-end ${getTransactionTypeColor(
                          transaction.transactionType
                        )}`}>
                        {getTransactionType(transaction.transactionType)}
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        )}
      </Container>
      <hr className="m-0"></hr>
      <div className="mt-2"></div>
      <Paginate response={transactions} onPaginate={onPaginate} />
    </>
  );
}
