import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import React from 'react';

export default function BetModal(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  return (
    <div className={props.onModalActive ? 'place-bet-modal' : 'place-bet-modal-close'}>
      <div className="pointer place-bet-modal-inner">
        <span className="modal-span">{props.successState ? t('Success') : t('Error')} </span>
      </div>
      {props.responseText}
      <div>
        <hr />
        <Container>
          <Row className="justify-content-center">
            <a
              className={`text-center pointer ${
                props.successState ? 'modal-button-success' : 'modal-button-error'
              }`}
              onClick={() => props.onModalClose(props.successState)}>
              {t('continue')}
            </a>
          </Row>
        </Container>
      </div>
    </div>
  );
}
