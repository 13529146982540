import { getSportTypeToIconForBetSlip, convertToTime } from './../../toolkit/utils';
import { useTranslation } from 'react-i18next';

function ResultCard(props) {
  const { t } = useTranslation('translations', { useSuspense: false });

  return (
    <div className="matchCard">
      <div className="d-flex justify-content-between">
        <div className="dayTime">
          <span className="day passive">{props.day}</span>
          <span className="time">{convertToTime(props.time)}</span>
        </div>
        <div className="ms-auto">
          <div className="league_name text-start" style={{ float: 'right' }}>
            {getSportTypeToIconForBetSlip(props.sportType) == null
              ? t(`sports.${props.sportType}`)
              : getSportTypeToIconForBetSlip(props.sportType)}
            {/* - {t(`tournaments.${props.match.tournamentId}`)} */}
          </div>
        </div>
      </div>
      <div className="teams_wrapper" style={{ paddingTop: 5 }}>
        <div className="team1 selected" style={{ fontSize: '15px' }}>
          <span>{props.team1}</span>
          <span>{props.scoreteam1}</span>
        </div>
        <div className="team2" style={{ fontSize: '15px' }}>
          <span>{props.team2}</span>
          <span>{props.scoreteam2}</span>
        </div>
      </div>
    </div>
  );
}
export default ResultCard;
