import { useCallback, useState, useEffect, useRef } from 'react';
import usePrevious from '../../toolkit/useHooks/usePrevious';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addBetToBetSlip, removeBetFromBetSlip } from 'stores/betSlip';
import useOddTransformer from '../../toolkit/useHooks/useOddsTransformer.js';
import { checkIfLiveEvent } from '../../toolkit/utils';

function OddButton(props) {
  const dispatch = useDispatch();
  const betSlip = useSelector((state) => state.betSlip);
  const { providerId, marketStringId, outcomeId } = props;
  const match = useSelector((state) => state.match.events[providerId]);
  const [selected, setSelected] = useState(false);
  const [outcome, setOutcome] = useState({});
  const [market, setMarket] = useState({});
  const [oddClass, setOddClass] = useState('');
  const [renderNull, setRenderNull] = useState(false);
  const prevOdds = usePrevious(outcome?.odds);
  const updateTimer = useRef(null);
  const hasOdds = outcome?.odds !== 0;
  const oddsTransformer = useOddTransformer();

  useEffect(() => {
    try {
      const newMarket = match.markets.find((m) => m.stringId === marketStringId);
      const newOutcome = newMarket.outcomes?.find((o) => o.id === outcomeId);
      setOutcome(newOutcome);
      setMarket(newMarket);
    } catch (error) {
      setRenderNull(true);
    }
  }, [match]);

  const actionBetSlipOdd = useCallback((e) => {
    e.preventDefault();
    if (!selected) {
      dispatch(
        addBetToBetSlip({
          providerId: providerId,
          marketStringId: marketStringId,
          marketId: market.id,
          outcomeId: outcomeId,
          outcomeName: outcome?.name,
          odds: outcome?.odds,
          match: match
        })
      );
    } else {
      dispatch(
        removeBetFromBetSlip({
          providerId: providerId,
          marketStringId: marketStringId,
          outcomeId: outcomeId
        })
      );
    }
  });

  useEffect(() => {
    const selectionStatus = betSlip.bets.some(
      (b) =>
        b.providerId === providerId &&
        b.marketStringId === marketStringId &&
        b.outcomeId === outcomeId
    );
    if (selected !== selectionStatus) {
      setSelected(selectionStatus);
    }
  }, [betSlip]);

  //   const usePreviousValue = (value) => {
  //     const ref = useRef();
  //     useEffect(() => {
  //       ref.current = value;
  //     });
  //     return ref.current;
  //   };

  function setUpdate(oddClass) {
    updateTimer.current = setTimeout(() => {
      setOddClass(oddClass);
      updateTimer.current = null;
    }, 5000);
  }

  useEffect(() => {
    const newOdd = outcome?.odds;
    if (prevOdds && newOdd !== prevOdds) {
      setOddClass(` odd-${newOdd > prevOdds ? 'increased' : 'decreased'}`);
      setUpdate('');
    }
  }, [outcome?.odds, prevOdds]);

  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
        updateTimer.current = null;
      }
    };
  }, []);

  if (renderNull || outcome?.odds == 1.0)
    return (
      <div className="justify-content-center d-inline-flex w-100 fw-bold px-1 lock-py-2 text-center text-muted betOddsBox light-black-svg">
        <img width="20" height="16" src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`} />
      </div>
    );
  return (
    <a
      key={props.outcomeId}
      className={`${selected ? 'betOddsBox-selected' : 'betOddsBox'}` + oddClass}
      onClick={actionBetSlipOdd}>
      {hasOdds
        ? oddsTransformer.transformWithDisplayFormatted(
            checkIfLiveEvent(match),
            match.isSeason,
            market.id,
            new Date(match.gameDate),
            outcome.odds
          )
        : '-'}
      {selected}
    </a>
  );
}
export default OddButton;
