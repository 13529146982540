import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import {
  gameDateToMatchTime,
  gameDateToMatchDay,
  MarketIdToMarketName,
  getSportTypeToIconForBetSlip,
  MyBetsTextCreator
} from './../../toolkit/utils';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { getTeamNameHelper } from '../../helper/matchDetailHelper';
import { EnumMatchStatusName } from './../../toolkit/Enums.js';

function BetMatchCard(props) {
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const match = props.match;
  const locales = useSelector((state) => state.match.locales);
  const { t } = useTranslation('translations', { useSuspense: false });
  const teams =
    match.home === null || match.away === null
      ? ['Home', 'Away']
      : [
          getTeamNameHelper(locales, selectedLocale, match.home, t),
          getTeamNameHelper(locales, selectedLocale, match.away, t)
        ];
  const sportType = props.match.sportType;
  let homeScore1,
    homeScore2,
    awayScore1,
    awayScore2,
    homeScore3,
    awayScore3 = 0;
  let firstHalfHomeScore, firstHalfAwayScore;
  if (props.match.periodScores) {
    homeScore1 = props.match.periodScores[0]?.homeScore;
    homeScore2 = props.match.periodScores[1]?.homeScore;
    homeScore3 = props.match.periodScores[2]?.homeScore;
    awayScore1 = props.match.periodScores[0]?.awayScore;
    awayScore2 = props.match.periodScores[1]?.awayScore;
    awayScore3 = props.match.periodScores[2]?.awayScore;
  }
  if (homeScore1 && homeScore2) {
    firstHalfHomeScore = homeScore1 + homeScore2;
    firstHalfAwayScore = awayScore1 + awayScore2;
  }

  const liveEvents = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]);
  }, shallowEqual);

  function getTime(props) {
    let timeLabel = '';
    if (match) {
      const { matchStatus } = props;
      switch (matchStatus) {
        case EnumMatchStatusName.HALFTIME: //half time
        case EnumMatchStatusName.FIRST_BREAK:
          timeLabel = t('components.matchcard.halftime');
          break;
        case EnumMatchStatusName.FIRST_HALF: //first half
        case EnumMatchStatusName.SECOND_HALF: //second half
          // eslint-disable-next-line no-case-declarations
          const liveMatch = liveEvents.find((e) => e.gameNumber === match.eventId);
          const timeText = liveMatch?.timeText ? Number(liveMatch.timeText.split(':')[0]) : null;
          if (matchStatus === EnumMatchStatusName.FIRST_HALF && timeText >= 45) {
            const stoppageTime = Number(liveMatch.timeTextStoppage.split(':')[0]) ?? 0;
            timeLabel = (
              <span>
                {matchStatus === EnumMatchStatusName.FIRST_HALF ? '45' : '90'}
                <sup>+{stoppageTime + 1}&apos;</sup>
              </span>
            );
            break;
          }
          if (matchStatus === EnumMatchStatusName.SECOND_HALF && timeText >= 90) {
            const stoppageTime = Number(liveMatch?.timeTextStoppage.split(':')[0]) ?? 0;
            timeLabel = (
              <span>
                {matchStatus === EnumMatchStatusName.FIRST_HALF ? '45' : '90'}
                <sup>+{stoppageTime + 1}&apos;</sup>
              </span>
            );
            break;
          }
          timeLabel = liveMatch?.timeText ? (
            <span>{Number(liveMatch.timeText?.split(':')[0]) + 1}&apos;</span>
          ) : null;
          break;
        case EnumMatchStatusName.PENALTIES: //Penalties
          timeLabel = t('components.matchcard.penalties');
          break;
        case EnumMatchStatusName.RETIRED: //retired
          timeLabel = t('components.matchcard.retired');
          break;
        default:
          timeLabel = '';
      }
    }
    return <span className="time">{timeLabel}</span>;
  }

  function getMatchStatusMatchDateCard(matchStatus, sportType) {
    let result = [];
    result.push(
      <span className="day2" key={'matchStatus-live'}>
        {t('components.matchcard.live')}
      </span>
    );
    const gameDayandTime = (
      <>
        <span className="day">
          {gameDateToMatchDay(props.startDate ? props.startDate : props.match.startDate)}
        </span>
        <span className="time">
          {gameDateToMatchTime(props.startDate ? props.startDate : props.match.startDate)}
        </span>
      </>
    );
    if (matchStatus == EnumMatchStatusName.ENDED) {
      return (
        <>
          <span className="day">
            {gameDateToMatchDay(props.startDate ? props.startDate : props.match.startDate)}
          </span>
          <span className="time">{t('components.matchcard.end')}</span>
        </>
      );
    } else {
      if (sportType == 0) {
        if (
          matchStatus > EnumMatchStatusName.NOT_STARTED &&
          matchStatus <= EnumMatchStatusName.SECOND_HALF
        ) {
          result.push(
            <span className="time" key={'matchStatus-time'}>
              {getTime(props.match)}
            </span>
          );
          return <>{result}</>;
        } else if (matchStatus == EnumMatchStatusName.HALFTIME) {
          result.push(
            <span className="time" key={'matchStatus-halftime'}>
              {t('components.matchcard.halftime')}
            </span>
          );
          return <>{result}</>;
        } else if (matchStatus == EnumMatchStatusName.PENALTIES) {
          result.push(
            <span className="time" key={'matchStatus-penalties'}>
              {t('components.matchcard.penalties')}
            </span>
          );
          return <>{result}</>;
        } else {
          return gameDayandTime;
        }
      } else {
        if (matchStatus == EnumMatchStatusName.NOT_STARTED || matchStatus == -1) {
          return gameDayandTime;
        } else {
          return (
            <>
              <span className="day2">{t('components.matchcard.live')}</span>
              <span className="time">{t(`matchStatus.${matchStatus}`)}</span>
            </>
          );
        }
      }
    }
  }
  const homeScores = [];
  const awayScores = [];
  const periodScoresLenght = props.match.periodScores?.length;
  props.match.periodScores?.forEach((element, index) => {
    if (index === periodScoresLenght - 1 && props.match.matchStatus != EnumMatchStatusName.ENDED) {
      homeScores.push(
        <span className="score" key={index}>
          {element.homeScore}{' '}
        </span>
      );
      awayScores.push(
        <span className="score" key={index}>
          {element.awayScore}{' '}
        </span>
      );
    } else {
      homeScores.push(<span key={index}>{element.homeScore} </span>);
      awayScores.push(<span key={index}>{element.awayScore} </span>);
    }
  });

  if (props.match.matchStatus == EnumMatchStatusName.ENDED) {
    homeScores.push(<span key={'homeScore'}> | {props.match.homeScore}</span>);
    awayScores.push(<span key={'awayScore'}> | {props.match.awayScore} </span>);
  }

  const getScore = (team, sportType, matchStatus) => {
    if (matchStatus != -1 && matchStatus != 0 && matchStatus != EnumMatchStatusName.ENDED) {
      periodScoresLenght;
      if (sportType == 13 || sportType == 2 || sportType == 12) {
        return <span>{team == 'team1' ? homeScores : awayScores}</span>;
      }
    }
    return <span>{team == 'team1' ? props.match.homeScore : props.match.awayScore}</span>;
  };

  function getFirstHalfScore(team, sportType) {
    if (props.match.periodScores && props.match.periodScores.length >= 1) {
      let firstHalfScore;
      if (team == 'team1') {
        firstHalfScore =
          sportType == 0
            ? homeScore1 + '|'
            : sportType == 1
              ? homeScore3
                ? firstHalfHomeScore + '|'
                : firstHalfHomeScore
              : '';
      } else {
        firstHalfScore =
          sportType == 0
            ? awayScore1 + '|'
            : sportType == 1
              ? awayScore3
                ? firstHalfAwayScore + '|'
                : firstHalfAwayScore
              : '';
      }
      return <span>{firstHalfScore}</span>;
    } else {
      return null;
    }
  }

  const get = (team, sportType, matchStatus) => {
    let array = [];
    if (props.match.matchStatus == EnumMatchStatusName.HALFTIME) {
      if (team == 'team1') {
        array.push(
          <span className="position-absolute top-50 start-0 ms" key={'halftime'}>
            {t('components.matchcard.halftime')}
          </span>
        );
      }
      if (sportType == 1 || sportType == 0) {
        array.push(<span key={'firstHalfScore'}>{getFirstHalfScore(team, sportType)}</span>);
      }
    } else {
      array.push(
        <span key={'score'}>
          {getFirstHalfScore(team, sportType)} {getScore(team, sportType, matchStatus)}
        </span>
      );
    }
    return array;
  };

  const getTeams = (team, sportType, matchStatus) => {
    const teamName = team == 'team1' ? teams[0] : teams[1];
    return (
      <div className={team}>
        <span>{teamName}</span>
        <span className="text-muted position-relative">{get(team, sportType, matchStatus)}</span>
      </div>
    );
  };

  let playerName = props.match.outcomeName.split('&');
  playerName = playerName[0].split(',')[1] + ' ' + playerName[0].split(',')[0];
  let marketId = props.match.marketId.split('-')[0];

  return (
    <div className="matchCard">
      <div className="d-flex justify-content-between">
        <div className="dayTime">
          {getMatchStatusMatchDateCard(props.match.matchStatus, sportType)}
        </div>
        {props.match.banker && (
          <div className="banker-bg" style={{ marginLeft: '30px', backgroundColor: '#3AA843' }}>
            <span className="banker-text">B</span>
          </div>
        )}
        <div className="ms-auto">
          <div className="league_name text-start" style={{ float: 'right' }}>
            {getSportTypeToIconForBetSlip(sportType) == null
              ? t(`sports.${sportType}`)
              : getSportTypeToIconForBetSlip(sportType)}
            {/* - {t(`tournaments.${props.match.tournamentId}`)} */}
          </div>
        </div>
      </div>
      <Row>
        <div className="teams_wrapper" style={{ paddingTop: 5, paddingLeft: 13 }}>
          {getTeams('team1', sportType, props.matchStatus)}
          {getTeams('team2', sportType, props.matchStatus)}
        </div>
      </Row>
      <div className="text-center my-1">
        {/* <Col xs={3} className="bets_name"></Col> */}
        <div className="bets_name">
          <small>
            Placed Score : {props.match.homeScoreAtPlaced} - {props.match.awayScoreAtPlaced}{' '}
          </small>
        </div>
      </div>
      <div className="px-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-12 text-muted">
            {MarketIdToMarketName(props.match.marketId.split('-')[0], props.match.marketId, false)}{' '}
            <span className="fw-bolder">
              {marketId === '888' ||
              marketId === '891' ||
              marketId === '40' ||
              marketId === '38' ||
              marketId === '39'
                ? MyBetsTextCreator(
                    sportType,
                    props.match.marketId.split('-')[0],
                    props.match.outcomeId,
                    props.match.outcomeName
                  ) +
                  ',' +
                  playerName
                : MyBetsTextCreator(
                    sportType,
                    props.match.marketId.split('-')[0],
                    props.match.outcomeId,
                    props.match.outcomeName
                  )}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="me-2 fw-bolder fs-12 text-muted">
              {props.match.placeBetOdds.toString()}
            </span>
            {props.match.outcomeStatus === 0 && (
              <img width="15" height="15" src={`${AWS_BUCKET_URL}/Icons/lost.svg`} />
            )}
            {props.match.outcomeStatus === 1 && (
              <img width="15" height="15" src={`${AWS_BUCKET_URL}/Icons/right.svg`} />
            )}
            {props.match.outcomeStatus === 3 && (
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/ban.svg`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default BetMatchCard;
