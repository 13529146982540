import { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { AWS_BUCKET_URL } from '../toolkit/constants';
import { useSelector } from 'react-redux';
import { ConvertDisplayNumber } from 'toolkit/NumberUtil';

const Footer = () => {
  const { t } = useTranslation('translations', { useSuspense: false });
  const user = useSelector((state) => state.auth.user);
  const betSlip = useSelector((state) => state.betSlip);
  const location = useLocation();

  if (location.pathname === '/slot-game' || location.pathname === '/casino') return <Fragment />;

  if (location.pathname === '/slot-game' || location.pathname === '/casino') return <Fragment />;

  return (
    <footer className="footer text-center container-fluid">
      <div className="hstack gap-1">
        <Col>
          <div className="footer-slot">
            <NavLink
              exact
              to={{ pathname: '/', league: '' }}
              className="py-2 d-block"
              isActive={() => location.pathname === '/' || location.pathname === '/start'}>
              <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/home.svg`} />
              <span>{t('start')}</span>
            </NavLink>
          </div>
        </Col>
        {/* icon sport */}
        <Col>
          <div className="footer-slot">
            <NavLink exact to="/sport" className="py-2 d-block">
              <span>
                <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/sports.svg`} />
              </span>
              <span>{t('sport')}</span>
            </NavLink>
          </div>
        </Col>
        {betSlip.bets.length > 0 ? (
          <Col>
            <NavLink exact to="/bet-slip" className="footer-circle-item">
              <div className="circle-overflow">
                <span className="circle">
                  <span>{betSlip.bets.length}</span>
                  {betSlip.totalOdds ? (
                    <span className={'small text-truncate fw-bold fs-12'}>{betSlip.totalOdds}</span>
                  ) : null}
                </span>
              </div>
              <span className={'circle-amount text-truncate fw-bold'}>
                {ConvertDisplayNumber(betSlip.maxWinning)}
              </span>
            </NavLink>
          </Col>
        ) : null}
        {/* icon live */}
        <Col>
          <div className="footer-slot">
            <NavLink exact to="/live" className="py-2 d-block">
              <span>
                <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/live.svg`} />
              </span>
              <span>{t('live')}</span>
            </NavLink>
          </div>
        </Col>
        {user === null ? (
          <Col>
            <div className="footer-slot">
              <NavLink exact to="/login" className="py-2 d-block">
                <span>
                  <img width="25" height={22} src={`${AWS_BUCKET_URL}/Icons/login.svg`} />
                </span>
                <span>{t('loginTitle')}</span>
              </NavLink>
            </div>
          </Col>
        ) : (
          <Col>
            <div className="footer-slot">
              <NavLink exact to="/mybets" className="py-2 d-block">
                <span>
                  <img
                    width="25"
                    height={22}
                    src={`${AWS_BUCKET_URL}/Icons/my-bets-inactive.svg`}
                  />
                </span>
                <span>{t('mybets')}</span>
              </NavLink>
            </div>
          </Col>
        )}
      </div>
    </footer>
  );
};

export default Footer;
