import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startPage: null,
  preMatches: {},
  page: 0,
  nextPageOnProgress: false,
  leagueMatches: {},
  sports: {},
  sport: '0',
  liveSports: {}
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStartPage: (state, action) => {
      state.startPage = action.payload;
    },
    setPreMatches: (state, action) => {
      state.preMatches = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setNextPageOnProgress: (state, action) => {
      state.nextPageOnProgress = action.payload;
    },
    setLeagueMatches: (state, action) => {
      state.leagueMatches = action.payload;
    },
    setSports: (state, action) => {
      state.sports = action.payload;
    },
    setSport: (state, action) => {
      state.sport = action.payload;
    },
    setLiveSports: (state, action) => {
      state.liveSports = action.payload;
    }
  }
});

export const {
  setStartPage,
  setPreMatches,
  setPage,
  setNextPageOnProgress,
  setLeagueMatches,
  setSports,
  setSport,
  setLiveSports
} = appSlice.actions;

export default appSlice.reducer;
