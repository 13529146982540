import axios from 'axios';
import { TMM_URL, BACKOFFICE_URL } from 'toolkit/constants';
import { useDispatch } from 'react-redux';
import { logout } from 'stores/auth';

export const tmmInstance = axios.create({
  baseURL: TMM_URL,
  headers: { 'app-key': 'bet-stack' }
});

export const backOfficeInstance = axios.create({
  baseURL: BACKOFFICE_URL,
  headers: { 'app-key': 'bet-stack' }
});

export const setToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    backOfficeInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    tmmInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete backOfficeInstance.defaults.headers.common.Authorization;
    delete tmmInstance.defaults.headers.common.Authorization;
  }
};

tmmInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // If the response status is 401 (Unauthorized), log out the user
    if (error.response.status === 401) {
      // Perform your logout action here
      localStorage.removeItem('token'); // Assuming you are using localStorage for authentication
      // Redirect the user to the login page or perform any other action as needed
      window.location.href = '/login';
    }
    // Pass the error on to the next handler
    return Promise.reject(error);
  }
);

backOfficeInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // If the response status is 401 (Unauthorized), log out the user
    if (error.response.status === 401) {
      // Perform your logout action here
      localStorage.removeItem('token'); // Assuming you are using localStorage for authentication
      // Redirect the user to the login page or perform any other action as needed
      window.location.href = '/login';
    }
    // Pass the error on to the next handler
    return Promise.reject(error);
  }
);