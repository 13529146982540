import { getLanguageCode } from 'toolkit/utils';
import { startLiveCasinoSessionReq } from 'stores/auth/services';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export default function VivoGames() {
  const user = useSelector((state) => state.auth.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    if (user) {
      startLiveCasinoSessionReq({
        customerId: user.id,
        language: getLanguageCode().split('-')[0]
      }).then((response) => {
        setIframeSrc(response.data);
        setSearchParams({ game: 'vivo' });
      });
    }
  }, []);

  const setIframeHeightOnLoad = () => {
    const iframe = document.getElementById('casinoFrame');
    if (iframe) {
      iframe.style.height = `${window.innerHeight - 50}px`;
    }
  };

  if (iframeSrc) {
    return (
      <>
        <iframe
          src={iframeSrc}
          id="casinoFrame"
          scrolling="yes"
          onLoad={setIframeHeightOnLoad}
          style={{
            width: '100vw',
            position: 'fixed',
            top: 50,
            right: 0,
            border: 'none',
            zIndex: 1050
          }}
        />
      </>
    );
  }

  return (
    <iframe
      src={iframeSrc}
      scrol
      className="w-100"
      style={{
        height: 'calc(88vh - 200px)'
      }}
    />
  );
}
