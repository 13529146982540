import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchResultCategoriesReq } from 'stores/match/services.js';
import { setCategoryNames } from 'stores/match/index.js';
import { AWS_BUCKET_URL } from 'toolkit/constants.js';
import { getSportTypeToActiveIcon, getSportTypeToWhiteIcon } from 'toolkit/utils';
import { TopLeagues } from 'toolkit/constants.js';
import SportDetailItem from 'components/general/SportDetailItem.js';
import SportListItem from 'components/sport/sport_list_item.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';

export default function Results() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations', { useSuspense: false });
  const categoryNames = useSelector((state) => state.match.categoryNames);
  const [selectedSport, setSelectedSport] = useState(null);
  const [resultCategories, setResultCategories] = useState(null);

  const getData = async () => {
    try {
      const response = await fetchResultCategoriesReq();
      setResultCategories(response.categories);
      dispatch(setCategoryNames(response.categoryNames));
      if (response.categories.Football) {
        setSelectedSport('Football');
      }
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  function calculateMenuItemsDynamic() {
    const menuItems = [];
    let icon = '';
    if (resultCategories) {
      Object.keys(resultCategories).forEach((sportKey, index) => {
        if (sportKey != 'EFootball' && sportKey != 'Other') {
          if (sportKey === selectedSport) {
            icon = getSportTypeToActiveIcon(selectedSport);
          } else {
            icon = getSportTypeToWhiteIcon(sportKey);
          }
          var matchCount = resultCategories[sportKey].length;
          if (matchCount > 0) {
            const isActive = selectedSport === sportKey;
            menuItems.push(
              <SportDetailItem
                key={index}
                active={isActive}
                icon={icon}
                onClick={() => {
                  setSelectedSport(sportKey);
                }}
                text={t(`sportType.${sportKey}`)}
                className={'d-flex flex-column gap-2'}
              />
            );
          }
        }
      });
      return menuItems;
    }
  }

  function leagueNames() {
    const topLeagues = [];
    const azLeagues = [];
    const leagueArray = [];

    if (resultCategories && resultCategories[selectedSport]) {
      resultCategories[selectedSport]
        .sort((a, b) => categoryNames[a]?.localeCompare(categoryNames[b]))
        .forEach((category, index) => {
          const code = category.split(':')[2];
          const icon = (
            <img
              width="20"
              height="13"
              src={`${AWS_BUCKET_URL}/ClientFlags/${code}.svg`}
              alt={code}
            />
          );

          if (category != 'tp:category:0') {
            const sportListItem = (
              <SportListItem
                key={index}
                to={`/results/${selectedSport}/${category}`}
                icon={icon}
                count={0}
                itemName={category}
                text={t(`categories.${category}`)}
                topLeagueIndex={TopLeagues.indexOf(category)}
              />
            );

            if (TopLeagues.includes(category)) {
              topLeagues.push(sportListItem);
            } else {
              azLeagues.push(sportListItem);
            }
          }
        });
    }
    leagueArray.push(
      getLeagues(
        topLeagues.sort((t1, t2) => t1.props.topLeagueIndex - t2.props.topLeagueIndex),
        t('topLeagues')
      ),
      getLeagues(azLeagues, t('azLeagues'))
    );
    return leagueArray;
  }

  function getLeagues(leagues, leagueName) {
    if (leagues.length > 0) {
      return (
        <div key={leagueName}>
          <h6>{leagueName}</h6>
          <div>{leagues}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <div className="overflow-auto text-nowrap mb-2 d-flex flex-row bg-dark">
        {calculateMenuItemsDynamic()}
      </div>
      <div className="container">
        <TitleHistoryBack title="Start" subTitle={t('results')} />
        <div>{leagueNames()}</div>
      </div>
    </div>
  );
}
