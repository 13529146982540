export function getMatchDetail(match) {
  let home = {
    ...match?.competitors[0],
    homeScore: match?.homeScore
  };

  let away = {
    ...match?.competitors[1],
    awayScore: match?.awayScore
  };
  return { home, away };
}

export function getTeamNameHelper(locales, locale, competitor, t) {
  if (
    locale === undefined ||
    locales === undefined ||
    locale === 'en' ||
    locales[locale][competitor?.id] === undefined
  ) {
    let baseName = competitor?.name;
    let age =
      competitor?.ageGroup?.length > 0 &&
      baseName.toLowerCase().indexOf(competitor?.ageGroup?.toLowerCase()) === -1
        ? ' ' + competitor?.ageGroup
        : '';
    if (age !== '') {
      const ageList = ['II', '(R)'];
      if (
        ageList.map((x) => {
          if (baseName.includes(x)) {
            baseName = baseName.replace(x, '');
          }
        })
      );
    }
    let gender = competitor?.gender === 'female' ? ' ' + t('womenId') : '';
    let totalName = baseName + age + gender;
    // if totalName length is greater than 24 remove basename char and all totalName length must be 24 character include age and gender add three dot after baseName remove age and gender length also baseName length

    if (totalName.length > 20) {
      let maxBaseNameLength = 20 - age.length - gender.length - 3;

      // Truncate or modify baseName to fit the limit
      baseName = baseName.slice(0, maxBaseNameLength);
      // Recreate totalName
      totalName = baseName + '...' + age + gender;
    }

    return totalName;
  } else {
    let baseName = locales[locale][competitor.id];
    let age =
      competitor?.ageGroup?.length > 0 &&
      baseName.toLowerCase().indexOf(competitor?.ageGroup?.toLowerCase()) === -1
        ? ' ' + competitor?.ageGroup
        : '';
    if (age !== '') {
      const ageList = ['II', '(R)'];
      ageList.map((x) => {
        if (baseName.includes(x)) {
          baseName = baseName.replace(x, '');
        }
      });
    }
    let gender = competitor?.gender === 'female' ? ' ' + t('womenId') : '';
    let totalName = baseName + age + gender;
    // if totalName length is greater than 24 remove basename char and all totalName length must be 24 character include age and gender add three dot after baseName remove age and gender length also baseName length

    if (totalName.length > 20) {
      let maxBaseNameLength = 20 - age.length - gender.length - 3;

      // Truncate or modify baseName to fit the limit
      baseName = baseName.slice(0, maxBaseNameLength);
      // Recreate totalName
      totalName = baseName + '...' + age + gender;
    }

    return totalName;
  }
}
