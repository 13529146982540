import React from 'react';
import { Row, Button, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GoBack from '../general/go_back';

export default function Contact(props) {
  const { t } = useTranslation('translations');

  return (
    <Container fluid>
      <Row>
        <Col xs={1}>
          <div className="mt5" />
          <GoBack />
        </Col>
        <Col>
          <div className="float-left mt-3">{t('myprofile')}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="float-left mt-3">{t('contact')}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <span>{t('contactsubtitle1')}</span>
          <span>{t('contactsubtitle2')}</span>
        </Col>
      </Row>
      <Row className="p-left-20 p-right-20 mt-2">
        <Button variant="success">support@norobet.de</Button>{' '}
      </Row>
    </Container>
  );
}
