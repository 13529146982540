import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Form, FloatingLabel, Alert } from 'react-bootstrap';
import GoBack from '../general/go_back';
import FetchButton from 'components/general/FetchButton';
import { errorToaster, showToaster } from 'mixin/general';
import { changePasswordReq } from 'services/AuthService';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function ChangePassword() {
  const { t } = useTranslation('translations');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: ''
  });

  const handleFormDataChange = (key, value) => {
    setFormData((oldValue) => ({ ...oldValue, [key]: value }));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error(t('pages.change_password.error_fill_form'));
    if (formData.newPassword !== formData.newPasswordRepeat)
      throw new Error(t('pages.change_password.error_unmatched_password'));
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await changePasswordReq(formData);
      if (!response) {
        throw new Error('Login error');
      }
      showToaster(t('pages.change_password.successMessageChangePassword'), 'success');
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };
  return (
    <Container>
      <Row className="mt-75 text-start align-items-center">
        <Col xs={1}>
          <GoBack />
        </Col>
        <Col>
          <div className="fw-bold fs-14">{t('myprofile')}</div>
        </Col>
      </Row>
      <hr className="my-75" />
      <Form noValidate validated={validated} onSubmit={handleLogin}>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="oldPassword"
            label={t('pages.change_password.form.old_password.label')}>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              onInput={(event) => handleFormDataChange('oldPassword', event.target.value)}
              placeholder={t('pages.change_password.form.old_password.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="newPassword"
            label={t('pages.change_password.form.new_password.label')}>
            <Form.Control
              type="password"
              minLength={8}
              value={formData.newPassword}
              onInput={(event) => handleFormDataChange('newPassword', event.target.value)}
              placeholder={t('pages.change_password.form.new_password.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="confirmPassword"
            label={t('pages.change_password.form.confirm_password.label')}>
            <Form.Control
              type="password"
              minLength={8}
              value={formData.newPasswordRepeat}
              onInput={(event) => handleFormDataChange('newPasswordRepeat', event.target.value)}
              placeholder={t('pages.change_password.form.confirm_password.placeholder')}
              required
            />
          </FloatingLabel>
        </Form.Floating>
        <Alert variant={'secondary'} className="d-flex align-items-center text-start">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/133.png`} />
          <span className="ms-2">{t('pages.change_password.info_password_requirements')}</span>
        </Alert>
        <FetchButton className="w-100" type="submit" variant="success" fetching={isSubmitting}>
          {t('pages.change_password.form.button_submit')}
        </FetchButton>
      </Form>
    </Container>
  );
}
