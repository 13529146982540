import { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import MatchCard from '../general/match_card.js';
import * as _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from 'components/general/loading.js';
import { addMatch } from 'stores/match';
import {
  getLanguageCode,
  // gameDateToMatchTime,
  sportTypeToSportId
} from 'toolkit/utils';
import { postSearchReq } from 'stores/match/services.js';

const Search = () => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();
  const [matches, setMatches] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [selectedMarketType, setSelectedMarketType] = useState({
    label: 'Winner',
    id: 1,
    bets: [1, 186, 202, 219, 251, 340]
  });
  const location = useLocation();
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchInput('');
  }, []);

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const onSearch = async (text) => {
    try {
      setSearching(true);
      if (text !== null && text.replace(/\s/g, '').length >= 1) {
        const language = getLanguageCode();
        const response = await postSearchReq({
          searchText: text,
          language: language
        });
        setMatches(response.matches);
        const data = { events: [...response.matches] };
        dispatch(addMatch(data));
      }
    } catch (error) {
      // error
    }
    setSearching(false);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        onSearch(searchInput);
        break;
      case 'Escape':
        setSearchInput('');
        break;
    }
  };

  function getBetType() {
    const betTypes = [
      {
        label: t('search.betTypes.1'),
        id: 1,
        bets: [1, 186, 202, 235, 219, 251, 340]
      },
      {
        label: t('search.betTypes.2'),
        id: 2,
        bets: [18] //, 19, 20, 58, 59, 68, 189, 225, 235, 236, 258, 260, 261, 238, 494]
      },
      {
        label: t('search.betTypes.3'),
        id: 3,
        bets: [14] //, 16, 65, 66, 187, 188, 203, 223, 237, 256, 493]
      },
      {
        label: t('search.betTypes.4'),
        id: 4,
        bets: [8] //, 9, 21, 23, 24, 25, 26, 30, 33, 34, 551]
      },
      {
        label: t('search.betTypes.5'),
        id: 5,
        bets: [29] //, 35, 36, 55, 75, 540, 542, 546]
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="tip-type" key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-12 ${
                selectedMarketType.id === betType.id ? 'selected' : ''
              }`}
              onClick={() => {
                setSelectedMarketType(betType);
              }}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getMatchCards() {
    const matchCards = [];
    if (matches.length > 0) {
      const sortedResult = _.sortBy(matches, 'gameDate');
      sortedResult.forEach((match, index) => {
        if (match.markets.length > 0 && selectedMarketType) {
          selectedMarketType?.bets?.forEach((item) => {
            const market = match.markets.find((e) => e.id === item);
            const winnerBettypes = [1, 186, 202, 235, 219, 251, 340];
            const checkPush =
              match.sport === sportTypeToSportId('Football') ||
              (match.sport !== sportTypeToSportId('Football') && winnerBettypes.indexOf(item) > -1);
            if (market !== undefined && checkPush) {
              matchCards.push(
                <Fragment key={match.league + match.gameNumber + item}>
                  <div className="text-start pb-2 fw-bold pt-1 fs-7">
                    {t(`sports.${match.sport}`) + ' / ' + match.category + ' / ' + match.league}
                  </div>
                  <MatchCard
                    key={match.gameNumber}
                    providerId={match.providerId}
                    match={match}
                    marketType={item}
                    index={index}
                    sport={match.sport}
                    path={location.pathname}
                  />
                </Fragment>
              );
            }
          });
        }
      });
    }
    return matchCards.length > 0 ? matchCards : <h3>{t('search.noResult')}</h3>;
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchInput);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  return (
    <div className="px-2 pt-2 container-fluid scrollable-y">
      <Form.Control
        type="input"
        //placeholder={t("search.press_enter")}
        value={searchInput}
        onChange={handleChange}
        autoFocus
        style={{
          color: '#000',
          borderBottomWidth: 0.5,
          fontSize: 16,
          borderColor: '#3AA843',
          width: '100%',
          margin: '0 0 5px 0'
        }}
        variant="outlined"
        onKeyDown={handleKeyDown}
      />

      <div className="">
        {searching ? (
          <Row>
            <Loading message={t('search.loadingMatches')} />
          </Row>
        ) : (
          matches.length > 0 && (
            <>
              <Row>
                <Col className="tip-types-container mt-2 mx-1">{getBetType()}</Col>
              </Row>
              {getMatchCards()}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default Search;
