import { useTranslation } from 'react-i18next';
function SubTitleH6(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  return (
    <>
      <h6 className="subTitle h6">{t(props.title)}</h6>
    </>
  );
}

export default SubTitleH6;
