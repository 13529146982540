import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { login } from 'stores/auth';
import FetchButton from 'components/general/FetchButton';
import { errorToaster } from 'mixin/general';
import { useEffect } from 'react';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function Login() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (!form.checkValidity()) throw new Error('Please fill all required fields');
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      handleSubmit(e);
      setIsSubmitting(true);
      const response = await dispatch(login({ username, password })).unwrap();
      if (!response) {
        throw new Error(t('invalid'));
      }
      setTimeout(() => {
        navigate('/');
      });
    } catch (error) {
      errorToaster(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Container fluid className="login d-flex flex-column justify-content-center">
      {/* <div className="logoWrapper mt-n5">
        <img
          className="logo"
          src={`${AWS_BUCKET_URL}/Icons/live90logofullwhite2.png`}
          alt="logoname"></img>
      </div> */}

      <div className="Login">
        <Form noValidate validated={validated} onSubmit={handleLogin}>
          <div className="mb-3">
            <Form.Control
              style={{
                color: '#000',
                padding: 0,
                paddingBottom: 5,
                paddingLeft: 0,
                borderBottomWidth: 0.5,
                fontSize: 16,
                borderColor: '#3AA843'
              }}
              required
              label={t('username')}
              placeholder="E-Mail/Customer Card"
              autoFocus
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
            <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
          </div>
          <div className="mt15"></div>
          <div className="mb-3">
            <Form.Control
              style={{
                color: '#000',
                padding: 0,
                paddingBottom: 5,
                paddingLeft: 0,
                borderBottomWidth: 0.5,
                fontSize: 16,
                borderColor: '#3AA843'
              }}
              required
              label={t('password')}
              placeholder={t('password')}
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
            />
            <Form.Control.Feedback type="invalid">{t('validpassword')}</Form.Control.Feedback>
          </div>
          {/* <div className="mb-3 text-start">
            <Form.Check className="fsize-20" type="checkbox" label={t('staylogged')} />
          </div> */}
          <div className="mt15"></div>
          <Form.Group>
            <FetchButton
              className="w-100 login-button"
              type="submit"
              variant="success"
              fetching={isSubmitting}>
              {t('login')}
            </FetchButton>
          </Form.Group>
        </Form>
      </div>
      {/* <div className="mt15"></div>
      <div className="fsize-20 text-center px-4">
        {t('forgetaccesdata')} |
        <Link to={'/register'} className="fw-bold d-block">
          {t('signup')}
        </Link>
      </div> */}
    </Container>
  );
}
