import GoBack from 'components/general/go_back';

const TitleHistoryBack = (props) => {
  const { title, subTitle } = props;
  return (
    <>
      <div className="d-flex align-items-center py-1">
        <GoBack />
        <div className="">
          <h1 className="fs-14 text-secondary text-uppercase mb-1 text-start">{title}</h1>
          <h6 className="float-start mb-0 fw-boldd">{subTitle}</h6>
        </div>
      </div>
      <hr className="mx-n3 mt-1" />
    </>
  );
};

export default TitleHistoryBack;
