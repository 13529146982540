import { useState, useEffect, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function Numpad(props) {
  const userSettings = useSelector((state) => state.auth.userSettings);
  const [value, setValue] = useState(props.initialValue || '1');
  const [willClear, setWillClear] = useState(true);
  const initialValue = props.initialValue || '1';
  const [preset, setPreset] = useState([5, 15, 20, 30, 50]);
  const stakeColor = ['#111516', '#222423', '#313332', '#414342', '#505251'];

  function onClick(v) {
    if (willClear && v !== '.' && v !== '0') {
      setValue(v);
      setWillClear(false);
    } else {
      let allowed = true;
      if (value.includes('.')) {
        if (v === '.') {
          allowed = false;
        } else {
          allowed = value.split('.')[1].length < 2;
        }
      }
      if (allowed) {
        let newValue = value + v;
        if (parseFloat(newValue) > 4000) {
          newValue = '4000.00';
        }
        setValue(newValue);
      }
    }
  }

  function deleteValue() {
    if (value.length === 1 || willClear) {
      setValue('1');
      setWillClear(true);
    } else {
      setValue(value.substr(0, value.length - 1));
    }
  }

  function onSuccess(value) {
    if (props.onSuccess) {
      props.onSuccess(parseFloat(value));
    }
  }

  useEffect(() => {
    if (userSettings) {
      const userSettingsPresets = [];
      for (let index = 1; index < 6; index++) {
        userSettingsPresets.push(userSettings[`presetStake${index}`]);
      }
      setPreset(userSettingsPresets);
    }
  }, [userSettings]);

  // useEffect(() => {
  //   // stake api
  //   const initialStake = { "1": 5, "2": 15, "3": 20, "4": 30, "5": 50 }
  //   setPreset(initialStake)
  // },[])

  return (
    <div className="numpad-container">
      <Row className="numpad-selected-values-container">
        <Col className="numpad-unit-value">Eur</Col>
        <Col className="numpad-selected-value">{value}</Col>
      </Row>
      <Row className="py-2 g-2 px-3">
        {preset.map((val, index) => (
          <div className="col" key={index}>
            <button
              onClick={() => onSuccess(val)}
              className="btn preset text-white w-100"
              style={{ backgroundColor: `${stakeColor[index]}` }}>
              <div>{val}</div>
            </button>
          </div>
        ))}
      </Row>
      <Row>
        <button onClick={() => onClick('1')} className="col numpad-button">
          1
        </button>
        <button onClick={() => onClick('2')} className="col numpad-button">
          2
        </button>
        <button onClick={() => onClick('3')} className="col numpad-button">
          3
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('4')} className="col numpad-button">
          4
        </button>
        <button onClick={() => onClick('5')} className="col numpad-button">
          5
        </button>
        <button onClick={() => onClick('6')} className="col numpad-button">
          6
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('7')} className="col numpad-button">
          7
        </button>
        <button onClick={() => onClick('8')} className="col numpad-button">
          8
        </button>
        <button onClick={() => onClick('9')} className="col numpad-button">
          9
        </button>
      </Row>
      <Row>
        <button onClick={() => onClick('.')} className="col numpad-button background-soft">
          .
        </button>
        <button onClick={() => onClick('0')} className="col numpad-button">
          0
        </button>
        <button onClick={() => deleteValue()} className="col numpad-button background-soft">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/72.png`} />
        </button>
      </Row>
      <Row>
        <button onClick={() => onSuccess(initialValue)} className="col numpad-button">
          Cancel
        </button>
        <button onClick={() => onSuccess(value)} className="col numpad-button">
          Ok
        </button>
      </Row>
    </div>
  );
}
