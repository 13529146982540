import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GoBack from '../general/go_back';

export default function ProfileSettings() {
  const { t } = useTranslation('translations', { useSuspense: false });
  const [stake, setStake] = useState(10);

  return (
    <Container>
      <Row>
        <Col xs={1}>
          <div className="mt5"></div>
          <GoBack />
        </Col>
        <Col xs={5} className="float-left ">
          <div className="float-left mt10">
            <div className="fweight-bold fsize-14">{t('consenthistory')}</div>
            {t('profile')}
          </div>
        </Col>
      </Row>

      <div className="mt5"></div>
      <hr className="m-0"></hr>
      <div className="mt15"></div>
      <>
        <Row>
          <Col xs={4} className="float-left fsize-11 fweight-bold gray-text">
            <div className="mt5"> {t('privacynotice')}</div>
          </Col>
          <Col
            xs={8}
            className="float-left"
            style={{ borderBottomColor: '#EDECEC', borderBottomWidth: '1', paddingBottom: '10' }}>
            <hr className="hr"></hr>
          </Col>
        </Row>

        <></>
        <Row>
          <Col>
            <div className="mt15"></div>
            <h8 className="float-left gray-text">{t('datapublish')}</h8>
            <h8 className="float-right">Jul 18, 2019, 12:00:00 AM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('dateaccept')}</h8>
            <h8 className="float-right">Dec 5, 2021, 2:38:29 PM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('status')}</h8>
            <h8 className="float-right green-title">{t('accept')}</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('versiontype')}</h8>
            <h8 className="float-right">MAJOR</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('version')}</h8>
            <h8 className="float-right">1.0</h8>
          </Col>
        </Row>

        <div className="mt15"></div>
        <Row>
          <Col xs={7} className="float-left fsize-11 fweight-bold gray-text">
            <div className="mt5 float-left"> {t('generalterms')}</div>
          </Col>
          <Col
            xs={5}
            className="float-left"
            style={{ borderBottomColor: '#EDECEC', borderBottomWidth: '1', paddingBottom: '10' }}>
            <hr className="hr"></hr>
          </Col>
        </Row>

        <Row>
          <Col
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10
            }}>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('datapublish')}</h8>
            <h8 className="float-right ">Nov 29, 2021, 10:40:04 AM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('dateaccept')}</h8>
            <h8 className="float-right ">Dec 5, 2021, 2:38:29 PM</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('status')}</h8>
            <h8 className="float-right green-title">{t('accept')}</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('versiontype')}</h8>
            <h8 className="float-right">MAJOR</h8>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt5"></div>
            <h8 className="float-left gray-text">{t('version')}</h8>
            <h8 className="float-right">4.3.9</h8>
          </Col>
        </Row>
      </>
    </Container>
  );
}
