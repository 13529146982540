import { useEffect, useState } from 'react';
import { Container, Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getSlotGamesReq } from 'stores/auth/services';
import Loading from 'components/general/loading';
import SlotItem from './SlotItem';
import _ from 'lodash';

const popularGamesId = [
  '6',
  '11',
  '1313',
  '1326',
  '1339',
  '1449',
  '1452',
  '1462',
  '1467',
  '1470',
  '1478',
  '1480',
  '4536',
  '5019',
  '5022',
  '7c762c9e3d54867eef1b12baa67dbc4c',
  '670bc0debe9a9a9228ac9548f39c0f69',
  'fca4509e8d744e43e11184d7a4a4258c',
  '25dc085f8a4bd0f592959989b397a684',
  'e018d57141b6a43777ec76fb3f3c9cc6',
  'f2ff5322f84957acfa8566351f79b2ef',
  '159549a343f156702a18ffbf3a0ab473',
  '0abd8ae23666ecdfd1dc951b7c2764c3',
  'b03705be3d6b725004b887795ba7536b',
  'e4b005b5773ed879bae9d7085509e75b'
];

export default function Slot() {
  const { t } = useTranslation('translations');
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([
    {
      title: t('allproviders'),
      key: 'allproviders',
      games: []
    }
  ]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getSlotGamesReq();
      const tempProviders = [
        {
          title: t('allproviders'),
          key: 'allproviders',
          games: []
        }
      ];
      const games = [...response.extraGames, ...Object.values(response.games)];
      response.providers = _.uniqBy(games, 'vendor').map((g) => g.vendor);
      tempProviders.push(
        ...response.providers.map((p) => ({
          title: p,
          key: p,
          games: []
        }))
      );
      games.forEach((game) => {
        if (popularGamesId.indexOf(game.id_game) > -1) {
          tempProviders[0].games.push(game);
        }
        const providerObjIndex = tempProviders.findIndex((p) => p.key === game.vendor);
        if (providerObjIndex > -1) {
          tempProviders[providerObjIndex].games.push(game);
        }
      });
      // sort temp providers by title and capitalize all letter
      tempProviders.sort((a, b) => a.title.localeCompare(b.title));
      tempProviders.forEach((p) => {
        p.title = p.title.toUpperCase();
      });
      tempProviders.unshift(
        tempProviders.splice(
          tempProviders.findIndex((p) => p.key === 'allproviders'),
          1
        )[0]
      );
      setProviders(tempProviders);
      setSelectedProvider(tempProviders[0]);
    } catch (error) {
      console.log(error);
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const filterProviderBySearch = (provider) => {
    return search.trim() !== ''
      ? provider.games.filter((g) => g.display_name.toLowerCase().includes(search.toLowerCase()))
          .length > 0
      : true;
  };

  const filterProviderGamesBySearch = (game) => {
    return search.trim() !== ''
      ? game.display_name.toLowerCase().includes(search.toLowerCase())
      : true;
  };

  if (loading) return <Loading message={t('loading_games')} />;

  return (
    <Container style={{ backgroundColor: '#171717', maxWidth: '100%' }}>
      <div className="row py-2 gy-3">
        <div className="col-6">
          <Form.Control
            type="text"
            placeholder={t('search_games')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-6">
          <Dropdown align={'end'}>
            <Dropdown.Toggle
              variant=""
              id="dropdown-providers"
              className="w-100 flex-center border">
              <span className="w-100 text-start text-capitalize" style={{ color: 'white' }}>
                {selectedProvider.title ?? t('allproviders')}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: 300, overflowY: 'auto' }}>
              {providers.map((provider) => (
                <Dropdown.Item
                  key={provider.key}
                  onClick={() => setSelectedProvider(provider)}
                  className="text-capitalize">
                  {provider?.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr className="col-12 mb-0" />
        {providers
          .filter((p) =>
            selectedProvider?.key === 'allproviders' ? true : p.key === selectedProvider.key
          )
          .filter((p) => filterProviderBySearch(p))
          .map((provider, i) => (
            <div
              className="col-12 col-md-4"
              key={`slot-item-${provider.key}`}
              style={{ padding: 0 }}>
              <SlotItem
                provider={provider?.title}
                games={provider.games.filter((g) => filterProviderGamesBySearch(g))}
              />
            </div>
          ))}
      </div>
    </Container>
  );
}
