import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { startLiveCasinoSessionReq } from 'stores/auth/services';
import { getLanguageCode, getDeviceType } from 'toolkit/utils';

export default function Casino() {
  const [src, setSrc] = useState('');
  const user = useSelector((state) => state.auth.user);
  const device = getDeviceType();

  function StartSession() {
    if(device === "iphone") return;
    startLiveCasinoSessionReq({ customerId: user.id, language: getLanguageCode().split('-')[0] })
      .then((r) => {
        setSrc(r.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  useEffect(() => {
    if (user === null) {
      return;
    }
    StartSession();
  }, []);

  return (
    <div>
      {device === 'desktop' && (
        <iframe
          allow="autoplay"
          id="mainframe"
          src={src}
          className="slot-size"
          style={{ padding: 0, border: 0, position: 'fixed' }}
          donotallowfullscreen=""
          webkitallowfullscreen="0"
          mozallowfullscreen="0"
          allowFullScreen="0"></iframe>
      )}
      {device === 'mobile' && (
        <iframe
          allow="autoplay"
          id="mainframe"
          src={src}
          className="slot-size"
          style={{ padding: 0, border: 0, position: 'fixed' }}></iframe>
      )}
    </div>
  );
}
