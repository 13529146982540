import { jwtDecode } from 'jwt-decode';
import { backOfficeInstance, tmmInstance } from 'libraries/axios';

export async function authLogin(username, password) {
  return await backOfficeInstance.post(`/Auth/CustomerSignIn`, {
    username,
    password
  });
}

export async function getCurrentUser() {
  const token = getToken();
  var correctToken = isAuthTokenValid(token);
  if (correctToken !== false) {
    return await backOfficeInstance.post(
      `/Auth/CheckToken`,
      {
        token,
        AccessDeviceEnum: 1
      },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
  } else {
    throw new Error('Failed to login with token.');
  }
}

export function getToken() {
  return localStorage.getItem('token');
}

export function getShopId() {
  const token = getToken();
  if (token === undefined || token === null || token.length === 0) return '';
  const decoded = jwtDecode(token);
  if (decoded === undefined || decoded === null) return '';
  return decoded.ShopId;
}

function isAuthTokenValid(access_token) {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return false;
  }
  return true;
}

export const updateProfileReq = async (formData) => {
  const response = await backOfficeInstance.post(`Customer/UpdateCustomerProfile`, formData);
  return response.data;
};

export const updateSettingsReq = async (formData) => {
  const response = await backOfficeInstance.post(`/Customer/UpdateCustomerSettings`, formData);
  return response.data;
};

export const getMyOpenBetsReq = async (params) => {
  const response = await tmmInstance.post(`/Bet/GetMyOpenBetSlips`, params);
  return response.data;
};

export const getMyClosedBetsReq = async (params) => {
  const response = await tmmInstance.post(`/Bet/GetMyClosedBetSlips`, params);
  return response.data;
};

export const getBettingHistory_WonReq = async (params) => {
  const response = await tmmInstance.post(`/Bet/GetMyHistoryWonBetSlips`, params);
  return response.data;
};

export const getBettingHistory_LostReq = async (params) => {
  const response = await tmmInstance.post(`/Bet/GetMyHistoryLostBetSlips`, params);
  return response.data;
};

export const getBettingHistory_CashoutReq = async (params) => {
  const response = await tmmInstance.post(`/Bet/GetMyHistoryCashoutBetSlips`, params);
  return response.data;
};

export const changePasswordReq = async (formData) => {
  const response = await backOfficeInstance.post(`/Auth/ChangePassword`, formData);
  return response.data;
};

export const getSlotGamesReq = async (params) => {
  const response = await backOfficeInstance.post(`/Wallet/GetCasinoGameList`, params);
  return response.data.data;
};

export const startSessionReq = async (params) => {
  const response = await backOfficeInstance.post(`/Wallet/OpenSession`, params);
  return response.data;
};

export const startLiveCasinoSessionReq = async (params) => {
  const response = await tmmInstance.post(`/Customer/OpenVivoLobby`, params);
  return response.data;
};

export const getEvoGamesReq = async () => {
  const response = await backOfficeInstance.get(`/LiveWallet`);
  return response.data.data;
};
