export default function SportDetailItem(props) {
  return (
    <>
      {props.splitItems === 'true' ? (
        <div
          onClick={() => props.onClick(props.sportType)}
          className="sport-item ml5 itemBlackBgColorWithRadius mt10">
          <span className="starIcon">{props.icon}</span>
          <span>
            <span className={'sport-item-text ' + (props.active === true ? 'activeColor' : '')}>
              {props.text != null && props.text.includes('tournaments') === true
                ? props.bet
                : props.text}{' '}
              {props.count !== undefined ? '(' + props.count + ')' : null}{' '}
            </span>
          </span>
        </div>
      ) : (
        <div onClick={() => props.onClick(props.sportType)} className="sport-item">
          <div
            className={
              'icon-size' +
              (props.selectedSportType === props.sportType ? ' active-icon-color' : '')
            }>
            {props.icon}
          </div>
          <div>
            <span
              className={
                'sport-item-text ' +
                (props.selectedSportType === props.sportType ? 'activeColor' : '')
              }>
              {' '}
              {props.text} {props.count !== undefined ? '(' + props.count + ')' : null}{' '}
            </span>
          </div>
        </div>
      )}
    </>
  );
}
